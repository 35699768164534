import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from '@material-ui/icons';

import { truncate } from 'utils/text-utils';

import useStyles from './collapsible-text.styles';

type CollapsibleTextProps = {
  text: string;
  maxLength: number;
  showMoreComponent?: React.ReactNode;
  showLessComponent?: React.ReactNode;
  className?: string;
};

const CollapsibleText: React.FC<CollapsibleTextProps> = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const truncatedText = expanded ? props.text : truncate(props.text, props.maxLength);

  const renderExpandButton = () => {
    if (truncatedText.length === props.text.length && !expanded) {
      return null;
    }

    if (expanded && props.showLessComponent) {
      return props.showLessComponent;
    }

    if (!expanded && props.showMoreComponent) {
      return props.showMoreComponent;
    }

    if (expanded && !props.showLessComponent) {
      return (
        <>
          <Typography variant="body2" color="primary">
            Show Less
          </Typography>
          <ArrowDropUpIcon />
        </>
      );
    }

    if (!expanded && !props.showMoreComponent) {
      return (
        <>
          <Typography variant="body2" color="primary" component="a">
            Show More
          </Typography>
          <ArrowDropDownIcon />
        </>
      );
    }
  };

  return (
    <>
      <span className={props.className}>{truncatedText}</span>
      <span className={classes.showMoreText} onClick={() => setExpanded(!expanded)}>
        {renderExpandButton()}
      </span>
    </>
  );
};

export default CollapsibleText;
