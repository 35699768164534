import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  skeletonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
  },
}));
