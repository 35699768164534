import { makeStyles } from '@material-ui/core/styles';

interface TextProps {
  color: string;
}

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    height: '100%',
    boxShadow: '0px 0px 24px -15px rgba(0,0,0,0.75)',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5)
    }
  },
  text: (props: TextProps) => ({
    color: props.color
  }),
  tooltip: {
    color: theme.palette.gunSmoke.main
  },
  severityCount: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(10)
  },
  chartContainer: {
    width: '100%',
    marginLeft: theme.spacing(10)
  }
}));
