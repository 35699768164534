import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

const SelectControlSkeleton = () => {
  return (
    <Grid container direction="column">
      <Grid item sm={3}>
        <Skeleton variant="rect" animation="wave" height={40} />
      </Grid>
      <Grid item sm={7}>
        <Skeleton variant="text" animation="wave" width="100%" />
        <Skeleton variant="text" animation="wave" width="70%" />
      </Grid>
    </Grid>
  );
};

export default SelectControlSkeleton;
