import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: theme.spacing(75),
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: theme.palette.common.white
  },
  body: {
    marginTop: theme.spacing(2)
  },
  bodyRow: {
    display: 'flex',
    alignItems: 'center'
  },
  bodyRowTitle: {
    fontWeight: 500,
    opacity: 0.6,
    marginRight: theme.spacing(2)
  },
  bodyRowValue: {
    fontWeight: 500
  }
}));
