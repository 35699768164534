import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  textChartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70%",
    width: "100%",
    color: theme.palette.oxfordBlue.main,
    [theme.breakpoints.down("xs")]: {
      height: "initial",
    },
  },
}));
