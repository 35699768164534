import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const ComplianceHeaderSkeleton = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" mr={10} maxWidth={100} maxHeight={100}>
        <Skeleton animation="wave" variant="rect" width={100} height={100} />
      </Box>
      <Box width="100%" maxWidth={1000}>
        <Skeleton animation="wave" variant="text" width="20%" />
        <Skeleton animation="wave" variant="text" width="60%" />
        <Skeleton animation="wave" variant="text" width="60%" />
        <Skeleton animation="wave" variant="text" width="50%" />
      </Box>
    </Box>
  );
};

export default ComplianceHeaderSkeleton;
