import { AxiosPromise } from 'axios';

import httpService from './http-service';

export const getHelp = (index: string): AxiosPromise<{ text: string }> => {
  return httpService.get(`/aws/help`, {
    params: {
      index
    }
  });
};
