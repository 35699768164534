import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(10),
    height: "100%",
    boxShadow: "0px 0px 24px -15px rgba(0,0,0,0.75)",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5),
    },
  },
  tooltip: {
    marginLeft: theme.spacing(10),
    color: theme.palette.gunSmoke.main,
    position: "absolute",
    right: theme.spacing(10),
  },
  textChartContainer: {
    margin: "auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(10),
  },
}));
