import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { DialogComponentProps } from 'interfaces/dialog-interface';

const ConfirmDeleteDialog = (props: DialogComponentProps) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Confirm action</DialogTitle>
      <DialogContent>Are you sure that you want to perform this action ?</DialogContent>

      <DialogActions>
        <Button color="primary" onClick={props.onClose} data-testid="close-button">
          Cancel
        </Button>

        <Button color="primary" onClick={props.onConfirm} data-testid="confirm-button">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
