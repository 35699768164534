import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { LineChart, Line, Tooltip, ResponsiveContainer, TooltipProps, Legend } from 'recharts';

import BarChartSkeleton from 'components/skeletons/charts-skeleton/bar-chart-skeleton';

import useStyles from './violations-summary-line-chart.styles';

interface IProps {
  values: { name: string; value: number }[] | undefined;
  lineColor: string;
  isLoading?: boolean;
}

const SimpleLineChart = (props: IProps) => {
  const classes = useStyles();
  if (props.isLoading) {
    return <BarChartSkeleton numberOfBars={6} maxBarHeight={60} />;
  }

  const CustomTooltip = ({ active, payload }: TooltipProps) => {
    if (active) {
      return (
        <>
          {payload && (
            <Paper className={classes.tooltipPaper}>
              <Box fontWeight="bold">{payload[0].payload.name}</Box>
              <Box className="label">{payload[0].value}</Box>
            </Paper>
          )}
        </>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer aspect={2} width="99%">
      <LineChart data={props.values}>
        <Tooltip isAnimationActive={false} content={CustomTooltip} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={props.lineColor}
          strokeWidth={5}
          r={0}
          isAnimationActive={false}
        />
        <Legend formatter={() => <span>90 Day Trendline</span>} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
