import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import * as DashboardStore from "redux/features/dashboard-store";

import {
  useViolationsSummary,
  useViolationSummaryByDate,
} from "hooks/useViolationsSummary";
import useIAMSummary from "hooks/useIAMSummary";
import useStorageSummary from "hooks/useStorageSummary";
import useViolationsStandards from "hooks/useViolationsStandards";
import useRemediationAgeSummary from "hooks/useRemediationAgeSummary";
import useInventorySummary from "hooks/useInventorySummary";

import SimpleBarChart from "components/charts/simple-bar-chart";
import SimpleTreemap from "components/charts/simple-treemap";
import VerticalComposedChart from "components/charts/vertical-composed-chart";
import SimpleTextChart from "components/charts/simple-text-chart";
import ViolationsSummaryBarChart from "components/charts/violations-summary-bar-chart";
import SectionWrapper from "components/section-wrapper";
import TextChartWrapper from "components/text-chart-wrapper";
import SummaryByDate from "./components/summary-by-date";

import useStyles from "./dashboard.styles";

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dateViolationsSummary = useViolationSummaryByDate();

  const {
    data: serviceViolationsSummary,
    loading: serviceViolationsSummaryLoading,
  } = useViolationsSummary();

  const {
    data: remediationAgeViolationsSummary,
    loading: remediationAgeViolationsSummaryLoading,
  } = useRemediationAgeSummary();

  const {
    data: violationsStandardsSummary,
    loading: violationsStandardsSummaryLoading,
  } = useViolationsStandards();

  const {
    data: [groupsCount, policiesCount],
    loading: IamSummaryLoading,
  } = useIAMSummary();

  const {
    data: inventorySummary,
    loading: inventorySummaryLoading,
  } = useInventorySummary();
  const {
    data: storageCount,
    loading: storageCountLoading,
  } = useStorageSummary();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(DashboardStore.onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div>
      <Grid container spacing={10}>
        <SummaryByDate summary={dateViolationsSummary} />

        <Grid item xs={12} sm={12} md={4}>
          <SectionWrapper
            title="Issue Count by Cloud Service"
            helpIndex="dashboard/violationsByService"
          >
            <ViolationsSummaryBarChart
              values={serviceViolationsSummary}
              isLoading={serviceViolationsSummaryLoading}
            />
          </SectionWrapper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <SectionWrapper title="Issue Aging" helpIndex="dashboard/issueAge">
            <VerticalComposedChart
              values={remediationAgeViolationsSummary}
              isLoading={remediationAgeViolationsSummaryLoading}
            />
          </SectionWrapper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <SectionWrapper
            title="NCI Count by Standards"
            helpIndex="dashboard/violationsByStandards"
          >
            <SimpleBarChart
              data={violationsStandardsSummary}
              isLoading={violationsStandardsSummaryLoading}
            />
          </SectionWrapper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={10} className={classes.textChartsConatiner}>
            <Grid item xs={12}>
              <TextChartWrapper
                title="Principals"
                helpIndex="dashboard/principals"
              >
                <SimpleTextChart
                  value={groupsCount}
                  isLoading={IamSummaryLoading}
                />
              </TextChartWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextChartWrapper title="Policies" helpIndex="dashboard/policies">
                <SimpleTextChart
                  value={policiesCount}
                  isLoading={IamSummaryLoading}
                />
              </TextChartWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextChartWrapper title="Storage" helpIndex="dashboard/storage">
                <SimpleTextChart
                  value={storageCount}
                  isLoading={storageCountLoading}
                />
              </TextChartWrapper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <SectionWrapper
            title="Asset Count by Cloud Service"
            helpIndex="inventory/servicesChart"
          >
            <SimpleTreemap
              data={inventorySummary}
              isLoading={inventorySummaryLoading}
            />
          </SectionWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
