import { AxiosPromise } from "axios";
import httpService from "./http-service";

import {
  ComplianceStandard,
  ComplianceSection,
  ComplianceControl,
  ComplianceControlRule,
  ComplianceControlsSummary,
  ComplianceServiceSummary,
  ComplianceViolationAsset,
} from "interfaces/compliance-interface";
import { DownloadFormat } from "interfaces/download-interface";

interface Response<T> {
  response: T;
}

export const listSupportedStandards = (): AxiosPromise<
  Response<ComplianceStandard[]>
> => {
  return httpService.get(`/aws/compliance/standards`);
};

export const listStandardSections = (
  standard: string
): AxiosPromise<Response<ComplianceSection[]>> => {
  return httpService.get(`/aws/compliance/sections`, {
    params: {
      standard,
    },
  });
};

export const listStandardControls = (
  section: string
): AxiosPromise<Response<ComplianceControl[]>> => {
  return httpService.get(`/aws/compliance/controls`, {
    params: {
      section,
    },
  });
};

export const listControlRules = (
  control: string
): AxiosPromise<Response<ComplianceControlRule[]>> => {
  return httpService.get(`/aws/compliance/rules`, {
    params: {
      control,
    },
  });
};

export const listGroupedSections = (standard: string): AxiosPromise<any> => {
  return httpService.get(`/aws/compliance/sections/group-by-function`, {
    params: {
      standard,
    },
  });
};

export const listControlsSummary = (
  standard: string,
  section: string
): AxiosPromise<ComplianceControlsSummary> => {
  return httpService.get(`/aws/compliance/controls/summary`, {
    params: {
      standard,
      section,
    },
  });
};

export const listServicesSummary = (
  standard: string,
  section: string
): AxiosPromise<Response<ComplianceServiceSummary[]>> => {
  return httpService.get(`/aws/compliance/services/summary`, {
    params: {
      standard,
      section,
    },
  });
};

export const listViolationsAssets = (
  ruleId: string
): AxiosPromise<{ violations: ComplianceViolationAsset[] }> => {
  return httpService.get(`/aws/compliance/violations`, {
    params: {
      "rule-id": ruleId,
    },
  });
};

export const scanComplianceControls = (
  section: string
): AxiosPromise<Response<ComplianceControl[]>> => {
  return httpService.put(`/aws/compliance/controls?section=${section}`);
};

export const downloadCompliance = (
  standard: string,
  format: DownloadFormat
) => {
  return httpService
    .get("/aws/compliance/download", {
      params: {
        standard,
        format,
      },
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "compliance." + format);
      document.body.appendChild(link);
      link.click();
    });
};
