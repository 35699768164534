import React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Paper, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { addAccount } from "redux/features/settings-store";

import useStyles from "./profile-settings-new-account.styles";

const ProfileSettingsNewAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addNewAccount = () => {
    dispatch(addAccount());
  };

  return (
    <Paper className={classes.newAccountWrapper} elevation={0}>
      <Box className={classes.newAccountContainer}>
        <IconButton onClick={addNewAccount}>
          <AddCircleIcon className={classes.addIcon} />
        </IconButton>
        <Typography variant="body1" className={classes.addAccountText}>
          Add more accounts
        </Typography>
      </Box>
    </Paper>
  );
};

export default ProfileSettingsNewAccount;
