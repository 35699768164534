import { Row, SortDirection } from 'interfaces/table-interface';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = <T, K>(order: SortDirection, orderBy: number) => {
  return order === SortDirection.DESC
    ? (a: Row<T, K>, b: Row<T, K>) => descendingComparator(a.values, b.values, orderBy)
    : (a: Row<T, K>, b: Row<T, K>) => -descendingComparator(a.values, b.values, orderBy);
};

export const sortTable = <T, K>(rows: Row<T, K>[], columnIndex: number, order: SortDirection) => {
  return rows.sort(getComparator<T, K>(order, columnIndex));
};
