import React from 'react';
import { TableCell } from '@material-ui/core';

import TableWrapper from 'components/table-wrapper';
import ControlTableRow from './components/control-table-row';

import useControlRules from 'hooks/useControlRules';

import { Row } from 'interfaces/table-interface';

import useStyles from './rules-table-container.styles';

interface IProps {
  controlId: string;
}

const RulesTableContainer = ({ controlId }: IProps) => {
  const classes = useStyles();
  const {
    data: [columns, rows],
    loading: controlRulesLoading
  } = useControlRules(controlId);

  const renderRow = (row: Row<string, any>) => {
    return <ControlTableRow key={`${row.ruleId}-${row.values[1]}`} numberOfColumns={7} row={row} />;
  };

  return (
    <div className={classes.expandedRow}>
      <TableWrapper
        isLoading={controlRulesLoading}
        columns={columns}
        rows={rows}
        renderRow={renderRow}
        emptyColumn={<TableCell size="small" />}
      />
    </div>
  );
};

export default RulesTableContainer;
