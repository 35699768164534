import React from 'react';
import { Paper } from '@material-ui/core';

import SelectControlSkeleton from 'components/skeletons/select-control-skeleton';
import SelectControl, { SelectControlProps } from '../select-control';
import SelectedControlDescription, {
  SelectedControlDescriptionProps
} from '../selected-control-description';

import useStyles from './select-control-header.styles';

type SelectControlHeaderProps = SelectControlProps &
  SelectedControlDescriptionProps & {
    isLoading: boolean;
  };

const SelectControlHeader: React.FC<SelectControlHeaderProps> = (props) => {
  const classes = useStyles();

  if (props.isLoading) {
    return (
      <Paper className={classes.wrapper} elevation={0}>
        <SelectControlSkeleton />
      </Paper>
    );
  }

  return (
    <Paper className={classes.wrapper}>
      <SelectControl value={props.value} values={props.values} onChange={props.onChange} />
      <SelectedControlDescription description={props.value.Section.description} />
    </Paper>
  );
};

export default SelectControlHeader;
