import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import BarChartSkeleton from "components/skeletons/charts-skeleton/bar-chart-skeleton";

import { ChartValue } from "interfaces/charts-interface";

import useStyles from "./simple-bar-chart.styles";

interface IProps {
  data: ChartValue[];
  isLoading?: boolean;
  aspect?: number;
}

const SimpleBarChart = (props: IProps) => {
  const classes = useStyles();

  if (props.isLoading) {
    return <BarChartSkeleton numberOfBars={10} maxBarHeight={150} />;
  }

  return (
    <ResponsiveContainer aspect={props.aspect} width="95%">
      <BarChart data={props.data} className={classes.barChart}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="shortName"
          interval={0}
          angle={-45}
          textAnchor="end"
          height={40}
        />
        <YAxis />
        <Tooltip
          wrapperStyle={{ zIndex: 10000 }}
          labelStyle={{ display: "none" }}
          formatter={(value, _, props) => [value, props.payload.name]}
          isAnimationActive={false}
        />
        <Bar dataKey="value" isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  );
};

SimpleBarChart.defaultProps = {
  aspect: 2,
};

export default SimpleBarChart;
