import React, { useState } from "react";
import { Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";

import NavigationDrawer from "./components/navigation-drawer";

import useStyles from "./layout-wrapper.styles";

interface IProps {
  children: React.ReactNode;
}

function LayoutWrapper({ children }: IProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <NavigationDrawer
            open={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <NavigationDrawer
            open={open}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
        <footer className={classes.footer}>
          <Typography variant="body2" color="textPrimary">
            © 2020 CloudRemedy | All Rights Reserved | 576 Fifth Avenue, Suite
            903 New York, NY 10036
          </Typography>
        </footer>
      </main>
    </div>
  );
}

export default LayoutWrapper;
