import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import { generateBarSizes } from 'utils/skeleton-utils';

import useStyles from './bar-chart-skeleton.styles';

interface IProps {
  numberOfBars: number;
  maxBarHeight: number;
}

const BarChartSkeleton = (props: IProps) => {
  const classes = useStyles();
  const sizes = generateBarSizes(props.numberOfBars, props.maxBarHeight);

  return (
    <Box className={classes.skeletonContainer}>
      {sizes.map((size, index) => (
        <Skeleton
          key={`${index}-${size.height}`}
          animation="wave"
          variant="rect"
          width={size.width}
          height={size.height}
        />
      ))}
    </Box>
  );
};

export default BarChartSkeleton;
