import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Box } from "@material-ui/core";

import {
  fetchActiveIntegration,
  setActiveIntegration,
} from "redux/features/settings-store";

import { IntegrationName } from "interfaces/settings-interface";

import useStyles from "./integrations-card.styles";

interface IProps {
  imageUrl: string;
  integration: IntegrationName;
  activeIntegration: IntegrationName;
}

const IntegrationsCard = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCardActive = props.activeIntegration === props.integration;

  const onIntegrationClick = async () => {
    dispatch(setActiveIntegration(props.integration));
    await dispatch(fetchActiveIntegration(props.integration));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.card, { [classes.activeCard]: isCardActive })}
      onClick={onIntegrationClick}
    >
      <img width={130} src={props.imageUrl} alt="Integration" />
    </Box>
  );
};

export default IntegrationsCard;
