import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@material-ui/core";

import {
  onPageInit,
  changeActiveStandard,
  changeActiveSection,
} from "redux/features/compliance-store";

import TabsWrapper from "components/tabs-wrapper";
import SectionWrapper from "components/section-wrapper";
import ComplianceHeader from "./components/compliance-header";
import SelectControlHeader from "./components/select-control-header";
import ComplianceTableContainer from "./containers/compliance-table-container";
import SectionsChartContainer from "./containers/sections-chart-container";
import ServicesChartContainer from "./containers/services-chart-container";

import useComplianceTabs from "hooks/useComplianceTabs";

import { AppState } from "interfaces/app-state-interface";
import {
  ComplianceStandard,
  ComplianceSection,
} from "interfaces/compliance-interface";

const Compliance = () => {
  const [localLoading, setLocalLoading] = useState(false);
  const loading = useSelector<AppState, boolean>((state) => state.ui.isLoading);
  const sections = useSelector<AppState, ComplianceSection[]>(
    (state) => state.compliance.sections
  );
  const activeStandard = useSelector<AppState, ComplianceStandard | undefined>(
    (state) => state.compliance.active_standard
  );
  const activeSection = useSelector<AppState, ComplianceSection | undefined>(
    (state) => state.compliance.active_section
  );
  const [tabs, currentTab] = useComplianceTabs();
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onChangeActiveStandard = async (standard_id: string) => {
    setLocalLoading(true);
    await dispatch(changeActiveStandard(standard_id));
    setLocalLoading(false);
  };

  const onChangeSection = (section_id: string) => {
    if (!section_id) {
      return;
    }

    dispatch(changeActiveSection(section_id));
  };

  return (
    <div>
      <TabsWrapper
        tabs={tabs}
        value={currentTab.value}
        isLoading={loading}
        onChange={(standard_id) => onChangeActiveStandard(standard_id)}
      />

      <Box mt={12} />

      <Grid container spacing={10}>
        <Grid item xs={12}>
          <ComplianceHeader
            title={activeStandard?.Standard.name}
            description={activeStandard?.Standard.description}
            image={activeStandard?.Standard.image}
            isLoading={loading && !activeStandard}
          />
        </Grid>

        {activeSection && (
          <Grid item xs={12}>
            <SelectControlHeader
              value={activeSection}
              values={sections}
              onChange={(section_id) => onChangeSection(section_id)}
              isLoading={localLoading}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={7} md={8}>
          <SectionWrapper
            title="Non Compliance By Sections"
            helpIndex="compliance/nciBySection"
          >
            <SectionsChartContainer isLoading={localLoading} />
          </SectionWrapper>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <SectionWrapper
            title="Non Compliance By Services"
            helpIndex="compliance/nciByService"
          >
            <ServicesChartContainer isLoading={localLoading} />
          </SectionWrapper>
        </Grid>
        <Grid item xs={12}>
          <ComplianceTableContainer isLoading={localLoading} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Compliance;
