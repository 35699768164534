import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  newAccountWrapper: {
    border: `dashed ${theme.spacing(0.75)}px ${theme.palette.alto.main}`,
    marginTop: theme.spacing(10)
  },
  newAccountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(8)
  },
  addIcon: {
    color: theme.palette.mercury.main,
    fontSize: theme.spacing(28)
  },
  addAccountText: {
    color: theme.palette.silver.main
  }
}));
