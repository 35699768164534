import React, { useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { IconButton, TableCell, TableRow, Tooltip } from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";

import {
  setViolationsSuppress,
  setViolationsUnSuppress,
  setViolationsSuppressStatus,
} from "redux/features/violations-store";

import { Row } from "interfaces/table-interface";

import useStyles from "./violations-row-assets.styles";

interface IProps {
  row: Row<string>;
  index: number;
  ruleId: string;
}

const ViolationsRowAssets = (props: IProps) => {
  const classes = useStyles();
  const [asset, region, account, suppressed] = props.row.values;
  const isEven = props.index % 2 === 0;
  const nodeLabel = props.row.ruleId;

  //redux
  const dispatch = useDispatch();

  //check if current node is suppressed
  let isSuppress = false;
  if (suppressed === undefined || suppressed === "false") {
    isSuppress = true;
  }

  //state
  const [isVisible, setVisible] = useState<boolean>(isSuppress);

  const tooltipTitle = isVisible ? "Suppress Finding" : "Un-suppress Finding";

  const onSuppressClick = async () => {
    const { ruleId } = props;

    if (isVisible) {
      await dispatch(setViolationsSuppress(ruleId, nodeLabel, asset));
    } else {
      await dispatch(setViolationsUnSuppress(ruleId, nodeLabel, asset));
    }

    dispatch(
      setViolationsSuppressStatus({ ruleId, asset, suppressStatus: !isVisible })
    );

    setVisible((prevVisibility) => !prevVisibility);
  };

  return (
    <TableRow
      className={clsx({
        [classes.grey]: !isEven,
      })}
    >
      <TableCell size="small">{asset}</TableCell>
      <TableCell size="small">{region}</TableCell>
      <TableCell size="small">{account}</TableCell>

      <TableCell>
        <Tooltip title={tooltipTitle}>
          <IconButton size="small" onClick={onSuppressClick}>
            {isVisible ? (
              <VisibilityIcon color="secondary" />
            ) : (
              <VisibilityOffIcon className={classes.subtextColor} />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
export default ViolationsRowAssets;
