import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  verticalChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%'
  },
  verticalChart: {
    '& .recharts-legend-wrapper': {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));
