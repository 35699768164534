import { createSlice } from "@reduxjs/toolkit";

import * as UiStore from "./ui-store";
import { AppThunk } from "interfaces/app-thunk-interface";

export interface DashboardStore {}

const initialState: DashboardStore = {};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
});

export const onPageInit = (): AppThunk => async (dispatch) => {
  if (localStorage.getItem("token")) {
    dispatch(loadHelpText());
  }
};

const loadHelpText = (): AppThunk => async (dispatch, store) => {
  const indexes = [
    "dashboard/highTrend",
    "dashboard/mediumTrend",
    "dashboard/lowTrend",
    "dashboard/violationsByService",
    "dashboard/violationsByStandards",
    "dashboard/inventory",
    "dashboard/issueAge",
    "dashboard/principals",
    "dashboard/policies",
    "dashboard/storage",
  ];

  for (const index of indexes) {
    dispatch(UiStore.getHelp(index));
  }
};

export const { reducer } = slice;
