import { AxiosPromise } from "axios";
import httpService from "./http-service";

import {
  AccountResponse,
  IntegrationType,
  IntegrationName,
} from "interfaces/settings-interface";
import { DownloadFormat } from "interfaces/download-interface";

export const listAccounts = (): AxiosPromise<{
  accounts: AccountResponse[];
}> => {
  return httpService.get("/aws/accounts");
};

export const saveSettings = (
  account: Partial<AccountResponse>
): AxiosPromise<void> => {
  return httpService.post("/aws/accounts", account);
};

export const testConnection = (
  accountId: string
): AxiosPromise<{ status: "success" | "failure" }> => {
  return httpService.get(`/aws/accounts/${accountId}/validate`);
};

export const scanAccounts = (accountId: string): AxiosPromise<void> => {
  return httpService.put(`/aws/accounts/${accountId}`);
};

export const deleteAccount = (accountId: string): AxiosPromise<void> => {
  return httpService.delete(`/aws/accounts/${accountId}`);
};

export const downloadAccount = (accountId: string, format: DownloadFormat) => {
  return httpService
    .get(`/aws/accounts/${accountId}/download`, {
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "accounts." + format);
      document.body.appendChild(link);
      link.click();
    });
};

export const listIntegration = (
  integration: IntegrationName
): AxiosPromise<IntegrationType> => {
  return httpService.get(`/integrations/${integration}`);
};

export const postIntegration = (
  integration: IntegrationName,
  integrationData: IntegrationType
): AxiosPromise<void> => {
  return httpService.post(`/integrations/${integration}`, {
    ...integrationData,
  });
};

export default {};
