import React from "react";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";
import { useTheme } from "@material-ui/core";
import TreemapSkeleton from "components/skeletons/charts-skeleton/treemap-skeleton";

import { ChartValue } from "interfaces/charts-interface";

import useStyles from "./simple-treemap.styles";

interface IProps {
  data: ChartValue[];
  isLoading?: boolean;
}

const SimpleTreemap = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles();

  if (props.isLoading) {
    return <TreemapSkeleton />;
  }

  return (
    <ResponsiveContainer aspect={2.2} height="85%" className={classes.root}>
      <Treemap
        data={props.data}
        dataKey="value"
        fill={theme.palette.common.white}
        isAnimationActive={false}
      >
        <Tooltip formatter={(value, _, props) => [value, props.payload.name]} />
      </Treemap>
    </ResponsiveContainer>
  );
};

export default SimpleTreemap;
