import React, { useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import WorldMapTooltipTitle from "./components/world-map-tooltip-title";

import { Marker as IMarker } from "interfaces/world-map-chart-interface";

export interface IProps {
  markers: IMarker[];
  onMarkerClick: (region: string) => void;
}

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

/* eslint-disable react-hooks/exhaustive-deps */
const WorldMapChart = (props: IProps) => {
  return useMemo(
    () => (
      <ComposableMap width={1000}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} fill="#DDD" />
            ))
          }
        </Geographies>
        {props.markers.map((marker) => (
          <Marker
            key={`marker-${marker.coordinates}`}
            coordinates={marker.coordinates}
            onClick={() => props.onMarkerClick(marker.region)}
            style={{
              default: { cursor: "pointer" },
              hover: { cursor: "pointer" },
            }}
            data-testid="world-map-marker"
          >
            <Tooltip title={<WorldMapTooltipTitle marker={marker} />}>
              <circle r={marker.summaryCount ? 5 : 0} fill="#F53" />
            </Tooltip>
          </Marker>
        ))}
      </ComposableMap>
    ),
    [props.markers]
  );
};

export default WorldMapChart;
