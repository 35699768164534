import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core";

import * as ComplianceStore from "redux/features/compliance-store";
import { truncate } from "utils/text-utils";

import { AppState } from "interfaces/app-state-interface";
import { ComplianceStandard } from "interfaces/compliance-interface";
import { ChartValue } from "interfaces/charts-interface";
import { FetchHook } from "interfaces/hooks-interface";

const useViolationsStandards = (): FetchHook<ChartValue[]> => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const standards = useSelector<AppState, ComplianceStandard[]>(
    (state) => state.compliance.standards
  );
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(ComplianceStore.fetchSupportedStandards());

    setLoading(false);
  };

  if (loading) {
    return {
      loading,
      data: [],
    };
  }

  const values: ChartValue[] = [];

  const {
    shade_400,
    shade_300,
    shade_200,
    shade_100,
  } = theme.palette.primaryBarChartShades;
  const COLOR_SHADES = [shade_400, shade_300, shade_200, shade_100];

  for (let standard of standards) {
    values.push({
      name: standard.Standard.short_name,
      shortName: truncate(standard.Standard.short_name, 10),
      value: standard.violations_count,
      fill: COLOR_SHADES[values.length % COLOR_SHADES.length],
    });
  }

  return {
    loading,
    data: values,
  };
};

export default useViolationsStandards;
