import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as InventoryStore from "redux/features/inventory-store";

import { AppState } from "interfaces/app-state-interface";
import { InventorySummary } from "interfaces/inventory-interface";
import { FetchHook } from "interfaces/hooks-interface";

const useStorageSummary = (): FetchHook<number> => {
  const [loading, setLoading] = useState(false);
  const { summary } = useSelector<AppState, InventorySummary>(
    (state) => state.inventory.storageSummary
  );
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(InventoryStore.fetchStorageSummary());

    setLoading(false);
  };

  if (loading) {
    return {
      loading,
      data: 0,
    };
  }

  let total = 0;

  for (let item of summary) {
    total += item.count;
  }

  return {
    loading,
    data: total,
  };
};

export default useStorageSummary;
