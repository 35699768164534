import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as AuthService from "services/auth-service";

import { AppThunk } from "interfaces/app-thunk-interface";

export interface AuthStore {
  isTokenValid: boolean;
}

const initialState: AuthStore = {
  isTokenValid: true,
};

const slice = createSlice({
  name: "activeRules",
  initialState,
  reducers: {
    setIsTokenValid: (store: AuthStore, action: PayloadAction<boolean>) => {
      const isValid = action.payload;

      if (!isValid) {
        localStorage.removeItem("token");
      }

      store.isTokenValid = action.payload;
    },
  },
});

export const listToken = (code: string): AppThunk => async (
  dispatch,
  store
) => {
  await AuthService.getToken(code)
    .then(({ data }) => {
      dispatch(setIsTokenValid(true));
      localStorage.setItem("token", data.id_token);
      window.location.replace(window.location.origin);
    })
    .catch(() => dispatch(setIsTokenValid(false)));
};

export const { reducer } = slice;
export const { setIsTokenValid } = slice.actions;
