import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

import * as ViolationsStore from "redux/features/violations-store";

import { AppState } from "interfaces/app-state-interface";
import { SummaryByRemediationAge } from "interfaces/violations-interface";
import { IssueAgeingChartValues } from "interfaces/charts-interface";
import { FetchHook } from "interfaces/hooks-interface";

const useRemediationAgeSummary = (): FetchHook<IssueAgeingChartValues[]> => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const summaries = useSelector<AppState, SummaryByRemediationAge[]>(
    (state) => state.violations.summaryByRemediationAge
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(ViolationsStore.fetchSummaryByRemediationAge());

    setLoading(false);
  };

  if (loading) {
    return {
      loading,
      data: [],
    };
  }

  let combined_average = 0;
  let open_violations_age = 0;
  let remediation_age = 0;

  for (let standard of summaries) {
    combined_average += get(standard, "combined_average", 0);
    open_violations_age += get(standard, "open_violations_age", 0);
    remediation_age += get(standard, "remediation_age", 0);
  }

  const maxAge = Math.max(
    Number(combined_average.toFixed(2)),
    Number(open_violations_age.toFixed(2)),
    Number(remediation_age.toFixed(2))
  );
  const ages = [180, 365, 540, 720];

  let ageIndex = 0;

  while (maxAge > ages[ageIndex]) {
    ageIndex++;
  }

  return {
    loading,
    data: [
      {
        name: "Range",
        value: ages[ageIndex],
        average: Number(combined_average.toFixed(2)),
        openViolationsAge: Number(open_violations_age.toFixed(2)),
        remediationAge: Number(remediation_age.toFixed(2)),
      },
    ],
  };
};

export default useRemediationAgeSummary;
