import React from "react";
import { useSelector } from "react-redux";
import { useTheme, Typography, Box } from "@material-ui/core";

import { truncate } from "utils/text-utils";

import BarChartSkeleton from "components/skeletons/charts-skeleton/bar-chart-skeleton";
import SimpleBarChart from "components/charts/simple-bar-chart";

import { AppState } from "interfaces/app-state-interface";
import { ComplianceServiceSummary } from "interfaces/compliance-interface";
import { ChartValue } from "interfaces/charts-interface";

type ServicesChartContainerProps = {
  isLoading?: boolean;
};

const ServicesChartContainer: React.FC<ServicesChartContainerProps> = (
  props
) => {
  const theme = useTheme();
  const loading = useSelector<AppState, boolean>((state) => state.ui.isLoading);
  const services_summary = useSelector<AppState, ComplianceServiceSummary[]>(
    (state) => state.compliance.services_summary
  );

  const {
    shade_400,
    shade_300,
    shade_200,
    shade_100,
  } = theme.palette.primaryBarChartShades;
  const COLOR_SHADES = [shade_400, shade_300, shade_200, shade_100];

  const chartValues: ChartValue[] = services_summary.map((service, index) => ({
    name: service.Service,
    shortName: truncate(service.Service, 8),
    value: service.ViolationsCount,
    fill: COLOR_SHADES[index % COLOR_SHADES.length],
  }));

  if (loading || props.isLoading) {
    return <BarChartSkeleton numberOfBars={5} maxBarHeight={150} />;
  }

  if (chartValues.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80%"
      >
        <Typography>0 Gaps</Typography>
      </Box>
    );
  }

  return <SimpleBarChart aspect={1.5} data={chartValues} />;
};

export default ServicesChartContainer;
