import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    height: "100%",
    boxShadow: "0px 0px 24px -15px rgba(0,0,0,0.75)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5),
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(5),
  },

  button: {
    fontWeight: "normal",
    textTransform: "initial",
    whiteSpace: "nowrap",
    padding: theme.spacing(4, 13),
  },
  arrowBtn: {
    padding: theme.spacing(2),
  },
  sectionTitle: {
    fontSize: theme.spacing(8),
  },
  sectionDescription: {
    fontSize: theme.spacing(7),
    fontWeight: 400,
  },
  tooltip: {
    color: theme.palette.gunSmoke.main,
  },
}));
