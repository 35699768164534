import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertProps } from "@material-ui/lab";

import eventEmitter, { Events, DialogEventKeys } from "../event-factory";

import { AppThunk } from "interfaces/app-thunk-interface";
import { DialogPayload } from "interfaces/dialog-interface";

interface Snackbars {
  text: string;
  severity?: AlertProps["severity"];
}

export interface NotificationsStore {
  dialog: DialogPayload;
  snackbars: { [key: string]: Snackbars };
}

const initialState: NotificationsStore = {
  dialog: {} as DialogPayload,
  snackbars: {},
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    createDialog: (
      state: NotificationsStore,
      action: PayloadAction<DialogPayload>
    ) => {
      state.dialog.type = action.payload.type;
      state.dialog.textProps = action.payload.textProps;
      state.dialog.defaultValues = action.payload.defaultValues;
      state.dialog.disabledFields = action.payload.disabledFields;
    },
    removeDialog: (state: NotificationsStore) => {
      state.dialog = {} as DialogPayload;
    },
    addSnackbar: (
      state: NotificationsStore,
      action: PayloadAction<Snackbars>
    ) => {
      state.snackbars[Date.now()] = action.payload;
    },
    removeSnackbar: (
      state: NotificationsStore,
      action: PayloadAction<string>
    ) => {
      delete state.snackbars[action.payload];
    },
  },
});

export const createDialog = <T = undefined, K = undefined>(
  payload: DialogPayload<T, K>
): AppThunk => async (dispatch, store) => {
  if (payload.onConfirm) {
    eventEmitter.once(
      Events.DialogEvent,
      DialogEventKeys.CONFIRM,
      payload.onConfirm
    );
  }

  dispatch(
    slice.actions.createDialog({
      type: payload.type,
      textProps: payload.textProps as any,
      defaultValues: payload.defaultValues as any,
      disabledFields: payload.disabledFields,
    })
  );
};

export const { reducer } = slice;
export const { addSnackbar, removeSnackbar, removeDialog } = slice.actions;
