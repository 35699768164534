import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { TableCell, TableRow, Collapse, Button } from '@material-ui/core';

import * as ComplianceStore from 'redux/features/compliance-store';

import CollapsibleText from 'components/collapsible-text';
import RulesTableRow from '../rules-table-row';

import { Row } from 'interfaces/table-interface';

import useStyles from './control-table-row.styles';

interface IProps {
  numberOfColumns: number;
  row: Row<string, Row<string>[]>;
}

function ControlTableRow({ numberOfColumns, row }: IProps) {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const noAssets = row.values[row.values.length - 1] === '0';

  const expandAssets = async () => {
    const expanded = isExpanded;
    setExpanded(!expanded);

    if (!expanded) {
      setLoading(true);
      await dispatch(ComplianceStore.fetchViolationsAssets(row.ruleId));
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow>
        {row.values.map((value, index) => (
          <TableCell
            key={index}
            className={clsx({
              [classes.descriptionCell]: index === 4 || index === 2
            })}
          >
            <CollapsibleText text={value} maxLength={120} />
          </TableCell>
        ))}
        <TableCell size="small" align="right">
          <Button
            disabled={noAssets}
            onClick={expandAssets}
            variant="contained"
            color="secondary"
            className={classes.viewAssetsBtn}
          >
            {isExpanded ? 'Hide' : 'View'} all assets
          </Button>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expansionRow}>
        <TableCell colSpan={numberOfColumns} className={classes.expansionCell}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <RulesTableRow isLoading={isLoading} rows={row.expanded} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default ControlTableRow;
