import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as ComplianceStore from "redux/features/compliance-store";

import SectionWrapper from "components/section-wrapper";
import ControlPreviewRow from "./components/control-preview-row";

import { AppState } from "interfaces/app-state-interface";
import { ComplianceControl } from "interfaces/compliance-interface";

type ComplianceTableContainerProps = {
  isLoading?: boolean;
};

const ComplianceTableContainer: React.FC<ComplianceTableContainerProps> = (
  props
) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleAll, setVisibleAll] = useState(false);
  const [isScanning, setScanning] = useState(false);
  const sectionId = useSelector<AppState, string | undefined>(
    (state) => state.compliance.active_section?.Section.node_id
  );
  const activeStandard = useSelector<AppState, string | undefined>(
    (state) => state.compliance.active_standard?.Standard.node_id
  );

  const controls = useSelector<AppState, ComplianceControl[]>(
    (state) => state.compliance.controls
  );
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, [sectionId]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setScanning(true);
    await dispatch(ComplianceStore.fetchStandardControls());
    setScanning(false);
  };

  const handleDownloadXLS = () => {
    if (activeStandard) {
      return dispatch(
        ComplianceStore.downloadCompliance(activeStandard, "xls")
      );
    }
  };

  const handleDownloadJSON = () => {
    if (activeStandard) {
      return dispatch(
        ComplianceStore.downloadCompliance(activeStandard, "json")
      );
    }
  };

  const handleOnScan = async () => {
    await dispatch(ComplianceStore.scanComplianceControls());
  };

  const handleSearch = (control: ComplianceControl) => {
    if (searchQuery.length === 0) {
      return control;
    }

    const stringifiedControl = JSON.stringify(control).toLowerCase();
    const lowerCasedSearchQuery = searchQuery.toLowerCase();

    return stringifiedControl.includes(lowerCasedSearchQuery);
  };

  const filteredControls = controls
    .filter((control) =>
      visibleAll ? control : control.violations_count !== 0
    )
    .filter((control) => handleSearch(control));

  return (
    <SectionWrapper
      title="Controls View"
      helpIndex="compliance/controlsViewTable"
      onScan={handleOnScan}
      onDownloadJSON={handleDownloadJSON}
      onDownloadXLS={handleDownloadXLS}
      onSearch={setSearchQuery}
      onToggleVisibility={(visibility) => setVisibleAll(visibility)}
    >
      {filteredControls.map((control) => (
        <ControlPreviewRow
          key={control.Control.control_id}
          loading={isScanning || props.isLoading}
          control={control}
        />
      ))}
    </SectionWrapper>
  );
};

export default ComplianceTableContainer;
