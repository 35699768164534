import { combineReducers } from "redux";

import * as UiStore from "./features/ui-store";
import * as NotificationsStore from "./features/notifications-store";
import * as InventoryStore from "./features/inventory-store";
import * as ActiveRulesStore from "./features/active-rules-store";
import * as ComplianceStore from "./features/compliance-store";
import * as ViolationsStore from "./features/violations-store";
import * as DashboardStore from "./features/dashboard-store";
import * as AuthSore from "./features/auth-store";
import * as SettingsStore from "./features/settings-store";

const rootReducer = () => {
  return combineReducers({
    ui: UiStore.reducer,
    notifications: NotificationsStore.reducer,
    inventory: InventoryStore.reducer,
    activeRules: ActiveRulesStore.reducer,
    compliance: ComplianceStore.reducer,
    violations: ViolationsStore.reducer,
    dashboard: DashboardStore.reducer,
    auth: AuthSore.reducer,
    settings: SettingsStore.reducer,
  });
};

export default rootReducer;
