import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";

import useStyles from "./inventory-table-row.styles";

import { ResourceTag } from "interfaces/inventory-interface";

interface IProps {
  title: string;
  tags?: ResourceTag[];
  attributes?: { [key: string]: string };
}

const InventoryTableRowSection = (props: IProps) => {
  const classes = useStyles();
  const noTags = props.tags && props.tags.length === 0;
  const noAttributes =
    props.attributes && Object.values(props.attributes).length === 0;

  return (
    <Grid item xs={6} className={classes.detailsColumn}>
      <Box mb={3} fontWeight="bold">
        {props.title}
      </Box>
      <Grid container justify="space-between" spacing={6}>
        {props.tags &&
          props.tags.map((tag) => (
            <Grid key={tag.key} item xs={6}>
              <Typography className={classes.resourceText} variant="subtitle2">
                <b>Key:{"  "}</b>
              </Typography>
              <Typography className={classes.resourceText} variant="body2">
                {tag.key}
              </Typography>
              <div>
                <Typography
                  className={classes.resourceText}
                  variant="subtitle2"
                >
                  <b> Value:{"  "}</b>
                </Typography>
                <Typography className={classes.resourceText} variant="body2">
                  {tag.value}
                </Typography>
              </div>
            </Grid>
          ))}
        {noTags && (
          <Grid item xs={6}>
            <Typography variant="body2">No tags available</Typography>
          </Grid>
        )}
        {props.attributes &&
          Object.entries(props.attributes).map(
            ([attributeName, attributeValue]) => (
              <Grid item key={attributeName} xs={6}>
                <Typography
                  className={classes.resourceText}
                  variant="subtitle2"
                >
                  <b>
                    {attributeName}:{"  "}
                  </b>
                </Typography>
                <Typography variant="body2" className={classes.resourceText}>
                  {attributeValue}
                </Typography>
              </Grid>
            )
          )}
        {noAttributes && (
          <Grid item xs={6}>
            <Typography variant="body2">No attributes available</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default InventoryTableRowSection;
