import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Image from 'components/image';
import ComplianceHeaderSkeleton from 'components/skeletons/compliance-header-skeleton';

import useStyles from './compliance-header.styles';

interface IProps {
  title?: string;
  description?: string;
  image?: string;
  isLoading?: boolean;
}

const ComplianceHeader = (props: IProps) => {
  const classes = useStyles();

  if (props.isLoading) {
    return <ComplianceHeaderSkeleton />;
  }

  return (
    <Box display="flex" alignItems="center" className={classes.headerWrapper}>
      <div className={classes.imageContainer}>
        <Image src={props.image} alt="image" width="100%" />
      </div>
      <Box maxWidth={1000} ml={10}>
        <Typography color="textPrimary" variant="body1">
          <b>{props.title}</b>
        </Typography>
        <Typography variant="body1">{props.description}</Typography>
      </Box>
    </Box>
  );
};

export default ComplianceHeader;
