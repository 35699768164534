import React from "react";
import ReactDOM from "react-dom";
import ApplicationContainer from "containers/application-container";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

const rootEl = document.getElementById("root");

const RenderApp = () => (
  <ApplicationContainer>
    <App />
  </ApplicationContainer>
);

ReactDOM.render(<RenderApp />, rootEl);

// Support for HMR
if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    ReactDOM.render(<RenderApp />, rootEl);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
