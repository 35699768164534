import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rowContainer: {
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.alto.main}`,
    padding: theme.spacing(8, 4),
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  rowTitle: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(9),
    wordBreak: 'break-all',
    maxWidth: theme.spacing(40)
  },
  actionButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.spacing(100)
  },
  violationsContainer: {
    width: theme.spacing(40),
    height: theme.spacing(20),
    borderRadius: theme.borderRadius.medium,
    display: 'flex',
    flexWrap: 'nowrap'
  },
  violationsBox: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText
  },
  compliantBoxColor: {
    backgroundColor: theme.palette.secondary.main
  },
  violationsBoxColor: {
    backgroundColor: theme.palette.error.main
  },
  expandButton: {
    borderRadius: '50%',
    border: `solid 1px ${theme.palette.alto.main}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary
  },
  rotateIcon: {
    transform: 'rotate(180deg)'
  },
  disabled: {
    opacity: 0.5
  }
}));
