const randomNumber = (min: number, max: number) => Math.floor(Math.random() * max) + min;

interface Size {
  width: number;
  height: number;
}

export const generateBarSizes = (count: number, maxHeight: number) => {
  const sizes: Size[] = [];

  for (let i = 0; i < count; i++) {
    const height = randomNumber(50, maxHeight);
    const width = 30;

    sizes.push({ height, width });
  }

  return sizes;
};
