import React from 'react';
import { Divider, Typography } from '@material-ui/core';

import { Marker } from 'interfaces/world-map-chart-interface';
import useStyles from './world-map-tooltip-title.styles';

interface IProps {
  marker: Marker;
}

const WorldMapTooltipTitle = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="body1" align="center" className={classes.bodyRowValue}>
          {props.marker.regionArea}
        </Typography>
        <Divider className={classes.divider} />
      </div>

      <div className={classes.body}>
        <div className={classes.bodyRow}>
          <Typography variant="body1" className={classes.bodyRowTitle}>
            Count:
          </Typography>
          <Typography variant="body1" className={classes.bodyRowValue}>
            {props.marker.summaryCount}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default WorldMapTooltipTitle;
