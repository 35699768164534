import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expansionRow: {
    backgroundColor: theme.palette.tableBackground.main
  },
  expansionCell: {
    padding: 0,
    border: 'none'
  },
  expandButton: {
    borderRadius: '50%',
    border: `solid 1px ${theme.palette.alto.main}`,
    marginLeft: theme.spacing(3)
  },
  rotateIcon: {
    transform: 'rotate(180deg)'
  }
}));
