import React, { useState } from "react";
import clsx from "clsx";
import {
  IconButton,
  TableCell,
  TableRow,
  Box,
  Collapse,
} from "@material-ui/core";
import {
  Create as CreateIcon,
  // Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

import CustomRulesTableRowExpanded from "./custom-rules-table-row-expanded";
import ActiveRulesSwitch from "../active-rules-switch";

import { IExpandedRule } from "interfaces/active-rules-interface";
import { Row } from "interfaces/table-interface";

import useStyles from "./custom-rules-table-row.styles";

interface IProps {
  row: Row<string | boolean, IExpandedRule>;
  handleActivate: (id: string, activate: boolean) => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
}

const CustomRulesTableRow = ({
  row,
  handleActivate,
  handleEdit,
  handleDelete,
}: IProps) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfColumns = row.values.length;
  const lastColumn = numberOfColumns - 1;
  const isChecked = row.values[lastColumn] as boolean;

  return (
    <>
      <TableRow>
        {row.values.map((rowCell, index) => {
          if (index === lastColumn) {
            return null;
          }

          return (
            <TableCell size="small" key={`row-value-${rowCell}`}>
              {rowCell}
            </TableCell>
          );
        })}
        <TableCell>
          <Box display="flex" justifyContent="center" alignContent="center">
            <ActiveRulesSwitch
              handleActivate={(activate) =>
                handleActivate(row.ruleId, activate)
              }
              isChecked={isChecked}
            />
            <IconButton size="small" onClick={() => handleEdit(row.ruleId)}>
              <CreateIcon fontSize="small" />
            </IconButton>
            {/* <IconButton size="small" onClick={() => handleDelete(row.ruleId)}>
              <DeleteIcon fontSize="small" color="error" className={classes.deleteIcon} />
            </IconButton> */}

            <IconButton
              className={classes.expandButton}
              size="small"
              onClick={() => setExpanded(!isExpanded)}
            >
              <ExpandMoreIcon
                className={clsx({
                  [classes.rotateIcon]: isExpanded,
                })}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expansionRow}>
        <TableCell
          size="small"
          colSpan={numberOfColumns}
          className={classes.expansionCell}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <CustomRulesTableRowExpanded expanded={row.expanded} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default CustomRulesTableRow;
