import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as InventoryStore from "redux/features/inventory-store";

import { AppState } from "interfaces/app-state-interface";
import { InventorySummary } from "interfaces/inventory-interface";
import { FetchHook } from "interfaces/hooks-interface";

const POLICIES_FIELDS = ["Resource Policies", "Policies"];

const useIamSummary = (): FetchHook<[number, number]> => {
  const [loading, setLoading] = useState(false);
  const { summary } = useSelector<AppState, InventorySummary>(
    (state) => state.inventory.iamSummary
  );
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(InventoryStore.fetchIamSummary());

    setLoading(false);
  };

  if (loading) {
    return {
      loading,
      data: [0, 0],
    };
  }

  let groupsCount = 0;
  let policiesCount = 0;

  for (let item of summary) {
    if (POLICIES_FIELDS.includes(item.resource)) {
      policiesCount += item.count;
    } else {
      groupsCount += item.count;
    }
  }

  return {
    loading,
    data: [groupsCount, policiesCount],
  };
};

export default useIamSummary;
