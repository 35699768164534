import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

interface IProps {
  rows: number;
}

const ControlPreviewSkeleton = (props: IProps) => {
  return (
    <Grid container spacing={10}>
      {[...Array(props.rows)].map((row) => [
        <Grid item sm={1} key={row}>
          <Skeleton variant="text" animation="wave" />
        </Grid>,
        <Grid item sm={9} key={row}>
          <Skeleton variant="text" animation="wave" />
        </Grid>,
        <Grid item sm={2} key={row}>
          <Skeleton variant="text" animation="wave" />
        </Grid>
      ])}
    </Grid>
  );
};

export default ControlPreviewSkeleton;
