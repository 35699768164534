import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { AppState } from "interfaces/app-state-interface";
import { Column, Row } from "interfaces/table-interface";
import { InventoryServiceArn } from "interfaces/inventory-interface";
import { FetchHook } from "interfaces/hooks-interface";
import * as InventoryStore from "redux/features/inventory-store";

const columns: Column[] = [
  {
    title: "Platform",
    sortable: true,
  },
  {
    title: "Account Number",
    sortable: true,
  },
  { title: "Service", sortable: true },
  { title: "Region", sortable: true },
  {
    title: "Resource Type",
    sortable: true,
  },
  {
    title: "Asset ID",
    sortable: false,
  },
];

type ReturnValue = [Column[], Row<string>[]];

export const useInventoryTable = (): FetchHook<ReturnValue> => {
  // state
  const [loading, setLoading] = useState(false);

  // redux
  const inventoryTable = useSelector<AppState, InventoryServiceArn[]>(
    (state) => state.inventory.arns
  );
  const dispatch = useDispatch();

  const tableRows: Row<string>[] = [];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(InventoryStore.fetchArns());

    setLoading(false);
  };

  inventoryTable.forEach((tableData) => {
    const { platform, account, service, region, type, arn } = tableData;

    tableRows.push({
      values: [platform, account, service, region, type, arn],
      ruleId: arn,
    });
  });

  if (loading) {
    return {
      data: [columns, []],
      loading,
    };
  }

  return {
    data: [columns, tableRows],
    loading,
  };
};
