import React from 'react';
import { Box } from '@material-ui/core';

import { IExpandedRule } from 'interfaces/active-rules-interface';

interface IProps {
  expanded?: IExpandedRule;
}

const CustomRulesTableRowExpanded = ({ expanded }: IProps) => {
  if (!expanded) {
    return null;
  }

  return (
    <Box m={10}>
      <Box>
        <Box fontWeight="bold" mt={5}>
          Description
        </Box>
        <Box>{expanded.description}</Box>
      </Box>

      <Box>
        <Box fontWeight="bold" mt={5}>
          Remediation
        </Box>
        <Box>{expanded.remediation}</Box>
      </Box>

      <Box>
        <Box fontWeight="bold" mt={5}>
          Query
        </Box>
        <Box>{expanded.query}</Box>
      </Box>
    </Box>
  );
};

export default CustomRulesTableRowExpanded;
