import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import * as ComplianceStore from "redux/features/compliance-store";

import { AppState } from "interfaces/app-state-interface";
import { FetchHook } from "interfaces/hooks-interface";
import { ComplianceControlRule } from "interfaces/compliance-interface";
import { Column, Row } from "interfaces/table-interface";

const columns: Column[] = [
  {
    title: "Platform",
    sortable: true,
  },
  {
    title: "Service",
    sortable: true,
  },
  {
    title: "Rule name",
    sortable: false,
  },
  {
    title: "Last Assessed",
    sortable: false,
  },
  {
    title: "Description",
    sortable: false,
  },
  {
    title: "NCI#",
    sortable: false,
  },
];

type RowsType = Row<string, Row<string>[]>;
type ReturnValue = [Column[], RowsType[]];

const useControlRules = (control_id: string): FetchHook<ReturnValue> => {
  const [loading, setLoading] = useState(false);
  const rules = useSelector<AppState, ComplianceControlRule[]>(
    (state) => state.compliance.control_rules
  );

  const dispatch = useDispatch();

  const currentControlRules = rules.filter(
    (rule) => rule.control_id === control_id
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);
    await dispatch(ComplianceStore.fetchControlRules(control_id));
    setLoading(false);
  };

  const adjustRulesToTableData = (): ReturnValue => {
    const rows: RowsType[] = [];

    for (const rule of currentControlRules) {
      const { Rule, Violations } = rule;
      const {
        platform,
        service,
        name,
        last_executed_on,
        description,
        rule_id,
      } = Rule;
      const last_executed_on_date = moment(last_executed_on).format("MM/DD/YY");

      rows.push({
        ruleId: rule_id,
        values: [
          platform,
          service,
          name,
          last_executed_on_date,
          description,
          rule.violations_count.toString(),
        ],
        expanded: Violations.map((violation) => ({
          ruleId: rule_id,
          values: [violation.Arn, violation.Region, violation.Account],
        })),
      });
    }

    return [columns, rows];
  };

  if (!currentControlRules && !loading) {
    return {
      data: [[], []],
      loading: false,
    };
  }

  return {
    data: adjustRulesToTableData(),
    loading,
  };
};

export default useControlRules;
