import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const InventoryExpandedRowSkeleton = () => {
  return (
    <Box padding={10}>
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Typography>
            <b>Tags</b>
          </Typography>
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <b>Attributes</b>
          </Typography>
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryExpandedRowSkeleton;
