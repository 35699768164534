import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RoutesContainer from 'routes';
import LayoutContainer from 'containers/layout-container';
import SnackbarContainer, { ISnackbars } from 'containers/snackbar-container';
import DialogContainer from 'containers/dialog-container';

import { removeSnackbar, removeDialog } from 'redux/features/notifications-store';

import { DialogPayload } from 'interfaces/dialog-interface';
import { AppState } from 'interfaces/app-state-interface';

const App = () => {
  const dialog = useSelector<AppState, DialogPayload>((state) => state.notifications.dialog);
  const snackbars = useSelector<AppState, ISnackbars>((state) => state.notifications.snackbars);
  const dispatch = useDispatch();

  return (
    <LayoutContainer>
      <RoutesContainer />

      {Object.keys(dialog).length > 0 && (
        <DialogContainer {...dialog} open={true} onClose={() => dispatch(removeDialog())} />
      )}

      <SnackbarContainer snackbars={snackbars} onClose={(key) => dispatch(removeSnackbar(key))} />
    </LayoutContainer>
  );
};

export default App;
