import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Box, Typography, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { HelpType } from 'interfaces/help-interface';

import { UiSelectors } from 'redux/selectors';

import useStyles from './text-chart-wrapper.styles';

interface IProps {
  title: string;
  helpIndex: HelpType;
  children: React.ReactNode;
}

const TextChartWrapper = (props: IProps) => {
  const classes = useStyles();
  const help = useSelector(UiSelectors.helpSelector);

  return (
    <Paper elevation={0} className={classes.root}>
      <Tooltip title={help[props.helpIndex]} className={classes.tooltip}>
        <HelpIcon fontSize="small" />
      </Tooltip>
      <Box className={classes.textChartContainer}>
        <Typography variant="subtitle1">
          <b>{props.title}</b>
        </Typography>

        {props.children}
      </Box>
    </Paper>
  );
};

export default TextChartWrapper;
