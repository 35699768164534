import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@material-ui/core";

import configureStore from "redux/configure-store";
import history from "routes/history";
import theme from "theme";

const store = configureStore();

interface IProps {
  children: React.ReactNode;
}

function ApplicationContainer({ children }: IProps) {
  return (
    <Provider store={store}>
      <Router history={history}>
        <CssBaseline />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Router>
    </Provider>
  );
}

export default ApplicationContainer;
