import React from "react";
import { Snackbar, SnackbarProps } from "@material-ui/core";
import { AlertProps, Alert } from "@material-ui/lab";

export interface IProps {
  open: boolean;
  message: string;
  type?: AlertProps["severity"];
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  anchorOrigin?: SnackbarProps["anchorOrigin"];

  onClose: () => void;
}

const CustomSnackbar = (props: IProps) => {
  return (
    <Snackbar
      ClickAwayListenerProps={{
        onClickAway: (event: React.MouseEvent<Document>) => {
          event.preventDefault();
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
      message={props.message}
    >
      {props.type && <Alert severity={props.type}>{props.message}</Alert>}
    </Snackbar>
  );
};

CustomSnackbar.defaultProps = {
  autoHideDuration: 2000,
};

export default CustomSnackbar;
