import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const CircularLoader = () => {
  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default CircularLoader;
