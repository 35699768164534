import React from "react";
import { useSelector } from "react-redux";
import { Paper, Typography, Tooltip, Box } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { UiSelectors } from "redux/selectors";

import SectionToolsWrapper, {
  IProps as ISectionTools,
} from "./components/section-tools-wrapper";

import useStyles from "./section-wrapper.styles";
import { HelpType } from "interfaces/help-interface";

interface IProps extends ISectionTools {
  title: string;
  description?: string;
  helpIndex?: HelpType;
  children: React.ReactNode;
}

const SectionWrapper = (props: IProps) => {
  const classes = useStyles();
  const help = useSelector(UiSelectors.helpSelector);

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.headerWrapper}>
        <Box width="100%">
          {props.title && (
            <>
              <Typography variant="subtitle2" className={classes.sectionTitle}>
                {props.title}
              </Typography>
              {props.description && (
                <Typography
                  variant="subtitle1"
                  className={classes.sectionDescription}
                >
                  {props.description}
                </Typography>
              )}
            </>
          )}

          {!props.title && (
            <SectionToolsWrapper
              defaultVisibility={props.defaultVisibility}
              toolsPosition={props.toolsPosition}
              onScan={props.onScan}
              onSearch={props.onSearch}
              onDownloadXLS={props.onDownloadXLS}
              onDownloadJSON={props.onDownloadJSON}
              onToggleVisibility={props.onToggleVisibility}
              visibilityTooltipText={props.visibilityTooltipText}
              onCreateCustomRule={props.onCreateCustomRule}
            />
          )}
        </Box>
        {props.helpIndex && (
          <Tooltip title={help[props.helpIndex]} className={classes.tooltip}>
            <HelpIcon fontSize="small" />
          </Tooltip>
        )}
      </div>

      {props.title && (
        <SectionToolsWrapper
          defaultVisibility={props.defaultVisibility}
          toolsPosition={props.toolsPosition}
          onScan={props.onScan}
          scanBtnTitle={props.scanBtnTitle}
          onSearch={props.onSearch}
          onDownloadXLS={props.onDownloadXLS}
          onDownloadJSON={props.onDownloadJSON}
          onToggleVisibility={props.onToggleVisibility}
          visibilityTooltipText={props.visibilityTooltipText}
        />
      )}

      {props.children}
    </Paper>
  );
};

export default SectionWrapper;
