import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  grey: {
    backgroundColor: theme.palette.tableBackground.main
  },
  subtextColor: {
    color: theme.palette.subtext.main
  }
}));
