import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import palette from "./palette";
import typography from "./typography";
import borderRadius from "./border-radius";

const theme = createMuiTheme({
  spacing: 2,
  palette,
  typography,
  borderRadius,
});

export default responsiveFontSizes(theme);
