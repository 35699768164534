import React, { useState, useEffect } from "react";
import PanoramaOutlinedIcon from "@material-ui/icons/PanoramaOutlined";

import useStyles from "./image.styles";

const Image = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [failed, setFailed] = useState(false);

  useEffect(() => setFailed(false), [props]);

  const classes = useStyles({
    width: props.width,
    height: props.height,
  });

  if (!props.src || failed) {
    return <PanoramaOutlinedIcon className={classes.noImageIcon} />;
  }

  return <img alt="" {...props} onError={() => setFailed(true)} />;
};

export default Image;
