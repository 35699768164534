import Dashboard from "pages/dashboard";
import Inventory from "pages/inventory";
import Violations from "pages/violations";
import Compliance from "pages/compliance";
import ActiveRules from "pages/active-rules";
import ProfileSettings from "pages/profile-settings";

import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import { Paths } from "interfaces/route-interface";

const paths: Paths = {
  dashboard: {
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    icon: DashboardOutlinedIcon,
  },
  inventory: {
    name: "Inventory",
    path: "/inventory",
    component: Inventory,
    icon: PublicOutlinedIcon,
  },
  violations: {
    name: "Violations",
    path: "/violations",
    component: Violations,
    icon: InfoOutlinedIcon,
  },
  compliance: {
    name: "Compliance",
    path: "/compliance",
    component: Compliance,
    icon: CheckCircleOutlinedIcon,
  },
  activeRules: {
    name: "Active Rules",
    path: "/active-rules",
    component: ActiveRules,
    icon: ListAltOutlinedIcon,
  },
  profileSettings: {
    name: "Settings",
    path: "/settings",
    component: ProfileSettings,
    icon: SettingsOutlinedIcon,
  },
};

export default paths;
