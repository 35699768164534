import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import { getInputError } from 'utils/form-utils';

import { CreateCustomRule } from 'interfaces/rule-interface';
import { DialogComponentProps } from 'interfaces/dialog-interface';

import useStyles from './create-custom-rule-dialog.styles';

export interface TextProps {
  title: string;
  cancelButton: string;
  submitButton: string;
}

const CreateCustomRuleDialog = ({
  open,
  defaultValues,
  disabledFields,
  textProps,
  onClose,
  onConfirm
}: DialogComponentProps<TextProps, CreateCustomRule>) => {
  const classes = useStyles();

  const definedDefaultValues = omitBy(defaultValues, isNil);

  const { values, touched, errors, handleChange, handleSubmit } = useFormik<CreateCustomRule>({
    initialValues: {
      platform: '',
      service: '',
      name: '',
      rule_id: '',
      description: '',
      remediation: '',
      query: '',
      is_active: false,
      severity: 'High',
      properties: {},
      ...definedDefaultValues
    },
    validationSchema: Yup.object().shape({
      platform: Yup.string().required(),
      service: Yup.string().required(),
      name: Yup.string().required(),
      rule_id: Yup.string().required(),
      description: Yup.string().required(),
      remediation: Yup.string().required(),
      query: Yup.string().required(),
      is_active: Yup.boolean().required(),
      severity: Yup.string().required()
    }),
    onSubmit: (values) => {
      onConfirm && onConfirm(values);
    }
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{textProps?.title}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            name="platform"
            label="Platform"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.platform}
            disabled={disabledFields?.includes('platform')}
            onChange={handleChange}
            {...getInputError('platform', touched, errors)}
          />
          <TextField
            name="service"
            label="Service"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.service}
            onChange={handleChange}
            disabled={disabledFields?.includes('service')}
            {...getInputError('service', touched, errors)}
          />
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.name}
            onChange={handleChange}
            disabled={disabledFields?.includes('name')}
            {...getInputError('name', touched, errors)}
          />
          <TextField
            name="rule_id"
            label="Rule Id"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.rule_id}
            onChange={handleChange}
            disabled={disabledFields?.includes('rule_id')}
            {...getInputError('rule_id', touched, errors)}
          />
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.description}
            onChange={handleChange}
            disabled={disabledFields?.includes('description')}
            {...getInputError('description', touched, errors)}
          />
          <TextField
            name="remediation"
            label="Remediation"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.remediation}
            onChange={handleChange}
            disabled={disabledFields?.includes('remediation')}
            {...getInputError('remediation', touched, errors)}
          />
          <TextField
            name="query"
            label="Query"
            variant="outlined"
            fullWidth
            className={classes.inputWrapper}
            value={values.query}
            onChange={handleChange}
            disabled={disabledFields?.includes('query')}
            {...getInputError('query', touched, errors)}
          />
          <FormControl variant="outlined" className={classes.inputWrapper} fullWidth>
            <InputLabel>Severity</InputLabel>
            <Select
              name="severity"
              value={values.severity}
              onChange={handleChange}
              label="Severity"
              disabled={disabledFields?.includes('severity')}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>

          <FormControl component="fieldset" className={classes.inputWrapper}>
            <FormControlLabel
              control={
                <Switch
                  name="is_active"
                  checked={!!values.is_active}
                  value={values.is_active}
                  onChange={handleChange}
                  color="primary"
                  disabled={disabledFields?.includes('is_active')}
                />
              }
              label="Active"
              labelPlacement="top"
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} data-testid="close-button">
          {textProps?.cancelButton}
        </Button>

        <Button color="primary" onClick={() => handleSubmit()} data-testid="confirm-button">
          {textProps?.submitButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateCustomRuleDialog.defaultProps = {
  textProps: {
    title: 'Create custom rule',
    cancelButton: 'Cancel',
    submitButton: 'Save'
  }
};

export default CreateCustomRuleDialog;
