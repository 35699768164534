import React from 'react';

import TableWrapper from 'components/table-wrapper';

import { Row, Column } from 'interfaces/table-interface';

import useStyles from './rules-table-row.styles';

const columns: Column[] = [
  {
    title: 'Asset',
    sortable: false
  },
  {
    title: 'Region',
    sortable: true
  },
  {
    title: 'Account number',
    sortable: false
  }
];

interface IProps {
  rows?: Row<string>[];
  isLoading: boolean;
}

function RulesTableRow(props: IProps) {
  const classes = useStyles();

  if (!props.rows) {
    return null;
  }

  return (
    <div className={classes.expandedAssetsContainer}>
      <TableWrapper columns={columns} rows={props.rows} isLoading={props.isLoading} />
    </div>
  );
}

export default RulesTableRow;
