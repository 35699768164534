export interface Column {
  title: string;
  sortable: boolean;
  alignment?: Alignment;
}

export interface Row<T, K = undefined> {
  values: T[];
  ruleId: string;
  expanded?: K;
}

export enum Alignment {
  center = 'center',
  left = 'left',
  right = 'right'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}
