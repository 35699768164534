import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const TreemapSkeleton = () => {
  return (
    <Grid container spacing={5}>
      <Grid container spacing={5}>
        <Grid item xs={2}>
          <Skeleton variant="rect" height="40px" />
        </Grid>
        <Grid item xs={10}>
          <Skeleton variant="rect" height="40px" />
        </Grid>
        <Grid item xs={7}>
          <Skeleton variant="rect" height="60px" />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant="rect" height="60px" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rect" height="80px" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rect" height="80px" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default TreemapSkeleton;
