import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import lodash from "lodash";

import * as SettingsService from "services/settings-service";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  AccountResponse,
  ConnectionAction,
  IntegrationName,
  IntegrationData,
  IntegrationType,
} from "interfaces/settings-interface";
import { DownloadFormat } from "interfaces/download-interface";

export interface SettingsStore {
  accounts: AccountResponse[];
  activeIntegration: IntegrationName;
  integrations: IntegrationData;
}

const initialState: SettingsStore = {
  accounts: [],
  activeIntegration: "slack",
  integrations: {
    slack: { "Service Url": "", active: true, connected: false },
    "pager-duty": {
      "Api Key": "",
      active: true,
      connected: false,
    },
    "service-now": {
      "Service Url": "",
      "User Id": "",
      Password: "",
      active: true,
      connected: false,
    },
    jira: {
      "Service Url": "",
      "User Id": "",
      "Api Key": "",
      "Project Key": "",
      active: true,
      connected: false,
    },
  },
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccounts: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse[]>
    ) => {
      store.accounts = action.payload;
    },
    addAccount: (store: SettingsStore) => {
      store.accounts.push({
        name: "",
        account: "",
        role: "",
        externalid: "",
        regions: [],
        notes: "",
        active: false,
      });
    },
    updateAccount: (
      store: SettingsStore,
      action: PayloadAction<AccountResponse>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex] = { ...action.payload };
      }
    },
    setConnection: (
      store: SettingsStore,
      action: PayloadAction<ConnectionAction>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload.account
      );

      if (currentAccountIndex > -1) {
        store.accounts[currentAccountIndex].connection = action.payload.status;
      }
    },
    deleteStoreAccount: (
      store: SettingsStore,
      action: PayloadAction<string>
    ) => {
      const currentAccountIndex = store.accounts.findIndex(
        (account) => account.account === action.payload
      );

      if (currentAccountIndex > -1) {
        store.accounts.splice(currentAccountIndex, 1);
      }
    },
    setActiveIntegration: (
      store: SettingsStore,
      action: PayloadAction<IntegrationName>
    ) => {
      store.activeIntegration = action.payload;
    },
    setIntegrationData: (
      store: SettingsStore,
      action: PayloadAction<IntegrationType>
    ) => {
      const { activeIntegration } = store;
      const integrationData = action.payload;

      if (!lodash.isEmpty(integrationData)) {
        store.integrations[activeIntegration] = {
          ...store.integrations[activeIntegration],
          ...integrationData,
        };

        store.integrations[activeIntegration].connected = true;
      }
    },
  },
});

export const fetchAccounts = (): AppThunk => async (dispatch, store) => {
  await SettingsService.listAccounts()
    .then(({ data }) => dispatch(setAccounts(data.accounts)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveSettings = (account: AccountResponse): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.saveSettings(account)
    .then(() => {
      dispatch(
        addSnackbar({
          text: `Account ${account.account} details saved.`,
          severity: "success",
        })
      );
      dispatch(updateAccount(account));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }
      let msg = ""
      if (error.response?.status === 405) {
        msg = error.response?.data.error
      }
      if (msg === undefined) {
        msg = "Can't fetch data"
      }
      dispatch(addSnackbar({ 
        text: msg,
        severity: "error" }));
    });
};

export const testConnection = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.testConnection(accountId)
    .then(({ data }) =>
      dispatch(setConnection({ account: accountId, status: data.status }))
    )
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const scanAccounts = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.scanAccounts(accountId)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const deleteAccount = (accountId: string): AppThunk => async (
  dispatch,
  store
) => {
  await SettingsService.deleteAccount(accountId)
    .then(() => {
      dispatch(deleteStoreAccount(accountId));
      dispatch(
        addSnackbar({
          text: `Account ${accountId} deleted.`,
          severity: "error",
        })
      );
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const downloadAccount = (
  accountId: string,
  format: DownloadFormat
): AppThunk => async (dispatch, store) => {
  await SettingsService.downloadAccount(accountId, format)
    .then((res) => res)
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const fetchActiveIntegration = (
  integration: IntegrationName
): AppThunk => async (dispatch, store) => {
  await SettingsService.listIntegration(integration)
    .then(({ data }) => dispatch(setIntegrationData(data)))
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const saveIntegration = (
  integration: IntegrationName,
  integrationData: IntegrationType
): AppThunk => async (dispatch, store) => {
  await SettingsService.postIntegration(integration, integrationData)
    .then(() => {
      dispatch(addSnackbar({ text: "Details saved", severity: "success" }));
      dispatch(setIntegrationData(integrationData));
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        dispatch(setIsTokenValid(false));
      }

      dispatch(addSnackbar({ text: "Can't fetch data" }));
    });
};

export const { reducer } = slice;
export const {
  setAccounts,
  addAccount,
  updateAccount,
  setConnection,
  deleteStoreAccount,
  setActiveIntegration,
  setIntegrationData,
} = slice.actions;
