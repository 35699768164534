import React, { useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, Chip } from "@material-ui/core";

import {
  fetchActiveIntegration,
  saveIntegration,
} from "redux/features/settings-store";
import { getHelp } from "redux/features/ui-store";

import SectionWrapper from "components/section-wrapper";
import IntegrationsForm from "./components/integrations-form";
import IntegrationsCard from "./components/integrations-card";

import SlackLogo from "__mocks__/logos/SlackLogo.png";
import PagerdutyLogo from "__mocks__/logos/PagerdutyLogo.png";
import ServiceNowLogo from "__mocks__/logos/ServiceNowLogo.png";
import JiraLogo from "__mocks__/logos/JiraLogo.png";

import { AppState } from "interfaces/app-state-interface";
import {
  IntegrationName,
  IntegrationType,
} from "interfaces/settings-interface";

import useStyles from "./profile-settings-integrations.styles";

const ProfileSettingsIntegrations = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const activeIntegration = useSelector<AppState, IntegrationName>(
    (state) => state.settings.activeIntegration
  );
  const integrationFields = useSelector<AppState, IntegrationType>(
    (state) => state.settings.integrations[activeIntegration]
  );

  const onPageInit = async () => {
    await dispatch(fetchActiveIntegration(activeIntegration));
    await dispatch(getHelp("settings/integrationsGrid"));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Box>
      <SectionWrapper title="" helpIndex="settings/integrationsGrid">
        <Box m={20} mt={0}>
          <Typography variant="body2">Select integration:</Typography>
          <Grid container spacing={10} alignItems="center" justify="center">
            <Grid item sm={6} md={3}>
              <IntegrationsCard
                imageUrl={SlackLogo}
                integration="slack"
                activeIntegration={activeIntegration}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <IntegrationsCard
                imageUrl={PagerdutyLogo}
                integration="pager-duty"
                activeIntegration={activeIntegration}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <IntegrationsCard
                imageUrl={ServiceNowLogo}
                integration="service-now"
                activeIntegration={activeIntegration}
              />
            </Grid>
            <Grid item sm={6} md={3}>
              <IntegrationsCard
                imageUrl={JiraLogo}
                integration="jira"
                activeIntegration={activeIntegration}
              />
            </Grid>
          </Grid>

          <Box
            className={clsx(classes.divider, {
              [classes.dividerWithChip]: integrationFields.connected,
            })}
          />

          {integrationFields.connected && (
            <Grid container justify="center">
              <Grid item xs={6} className={classes.chipContainer}>
                <Chip
                  className={classes.connectedChip}
                  label="Connected"
                  size="small"
                  color="secondary"
                />
              </Grid>
            </Grid>
          )}

          <IntegrationsForm
            onSubmit={(integrationData) =>
              dispatch(saveIntegration(activeIntegration, integrationData))
            }
            integrationFields={integrationFields}
          />
        </Box>
      </SectionWrapper>
    </Box>
  );
};

export default ProfileSettingsIntegrations;
