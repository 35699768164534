// recharts-default-legend
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  barChart: {
    marginLeft: theme.spacing(-10),
    '& .recharts-surface': {
      overflow: 'visible',
      height: '90%'
    },
    '& .recharts-legend-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(10),

      '& .recharts-default-legend': {
        display: 'flex',
        flexDirection: 'row-reverse'
      }
    }
  }
}));
