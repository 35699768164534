import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as ViolationsService from "services/violations-service";
import * as UiStore from "./ui-store";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { AppThunk } from "interfaces/app-thunk-interface";
import {
  Violation,
  SummaryByDate,
  SummaryByService,
  SummaryByRemediationAge,
  NodeData,
} from "interfaces/violations-interface";
import { DownloadFormat } from "interfaces/download-interface";

export interface ViolationsStore {
  misconfig: Violation[];
  summaryByDate: SummaryByDate;
  summaryByService: SummaryByService[];
  summaryByRemediationAge: SummaryByRemediationAge[];
}

const initialState: ViolationsStore = {
  misconfig: [],
  summaryByDate: {
    date_summary: [],
    severity_count: [],
  },
  summaryByService: [],
  summaryByRemediationAge: [],
};

const slice = createSlice({
  name: "violations",
  initialState,
  reducers: {
    setViolations: (
      store: ViolationsStore,
      action: PayloadAction<Violation[]>
    ) => {
      store.misconfig = action.payload;
    },
    setSummaryByDate: (
      store: ViolationsStore,
      action: PayloadAction<SummaryByDate>
    ) => {
      store.summaryByDate = action.payload;
    },
    setSummaryByService: (
      store: ViolationsStore,
      action: PayloadAction<SummaryByService[]>
    ) => {
      store.summaryByService = action.payload;
    },
    setSummaryByRemediationAge: (
      store: ViolationsStore,
      action: PayloadAction<SummaryByRemediationAge[]>
    ) => {
      store.summaryByRemediationAge = action.payload;
    },
    setViolationsSuppressStatus: (
      store: ViolationsStore,
      action: PayloadAction<NodeData>
    ) => {
      const violations = store.misconfig;

      const violationRow = violations.find(
        (violation) => violation.rule.rule_id === action.payload.ruleId
      );

      const currentNode = violationRow?.resources.find(
        (resource) =>
          resource.nodes[0].properties.node_id === action.payload.asset
      );

      if (currentNode) {
        currentNode.suppressed = action.payload.suppressStatus;
      }
    },
  },
});

export const onPageInit = (): AppThunk => async (dispatch, store) => {
  dispatch(UiStore.setLoading(true));
  await dispatch(fetchViolations());
  dispatch(loadHelpText());
  dispatch(UiStore.setLoading(false));
};

const loadHelpText = (): AppThunk => async (dispatch, store) => {
  const indexes = ["violations/table"];

  for (const index of indexes) {
    dispatch(UiStore.getHelp(index));
  }
};

export const fetchViolations = (suppress?: boolean): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    if (suppress === undefined) {
      await ViolationsService.listViolations()
        .then(({ data }) => {
          dispatch(setViolations(data.misconfig));
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            dispatch(setIsTokenValid(false));
          }

          dispatch(addSnackbar({ text: "Can't fetch data" }));
        });
    } else {
      await ViolationsService.listViolations(suppress)
        .then(({ data }) => {
          dispatch(setViolations(data.misconfig));
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            dispatch(setIsTokenValid(false));
          }

          dispatch(addSnackbar({ text: "Can't fetch data" }));
        });
    }
  }
};

export const fetchSummaryByService = (): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.listSummaryByService()
      .then((res) => {
        dispatch(setSummaryByService(res.data.response));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchSummaryByDate = (): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.listSummaryByDate()
      .then((res) => {
        dispatch(setSummaryByDate(res.data));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const fetchSummaryByRemediationAge = (): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.listSummaryByRemediationAge()
      .then((res) => {
        dispatch(setSummaryByRemediationAge(res.data.response));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const scanViolations = (includeSuppressed = true): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.scanViolations(includeSuppressed)
      .then(({ data }) => dispatch(setViolations(data.misconfig)))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const downloadViolations = (format: DownloadFormat): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.downloadViolations(format)
      .then((res) => res)
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const setViolationsSuppress = (
  ruleId: string,
  nodeLabel: string,
  nodeId: string
): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.listViolationsSuppress(ruleId, nodeLabel, nodeId)
      .then((res) => res)
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const setViolationsUnSuppress = (
  ruleId: string,
  nodeLabel: string,
  nodeId: string
): AppThunk => async (dispatch, store) => {
  if (localStorage.getItem("token")) {
    await ViolationsService.listViolationsUnSuppress(ruleId, nodeLabel, nodeId)
      .then((res) => res)
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "Can't fetch data" }));
      });
  }
};

export const { reducer } = slice;
export const {
  setSummaryByDate,
  setSummaryByService,
  setSummaryByRemediationAge,
  setViolations,
  setViolationsSuppressStatus,
} = slice.actions;
