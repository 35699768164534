import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expandedRowWrapper: {
    padding: theme.spacing(10)
  },
  expandedRowContainer: {
    backgroundColor: theme.palette.common.white
  },
  withBorder: {
    border: `solid 1px ${theme.palette.alto.main}`
  },
  containerPadding: {
    padding: theme.spacing(6, 10)
  }
}));
