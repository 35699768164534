import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';

import { truncate } from 'utils/text-utils';

import SimpleBarChart from 'components/charts/simple-bar-chart';
import BarChartSkeleton from 'components/skeletons/charts-skeleton/bar-chart-skeleton';

import { AppState } from 'interfaces/app-state-interface';
import { ComplianceSection } from 'interfaces/compliance-interface';
import { ChartValue } from 'interfaces/charts-interface';

type SectionsChartContainerProps = {
  isLoading?: boolean;
};

const SectionsChartContainer: React.FC<SectionsChartContainerProps> = (props) => {
  const sections = useSelector<AppState, ComplianceSection[]>((state) => state.compliance.sections);
  const loading = useSelector<AppState, boolean>((state) => state.ui.isLoading);
  const theme = useTheme();

  const { shade_400, shade_300, shade_200, shade_100 } = theme.palette.primaryBarChartShades;
  const COLOR_SHADES = [shade_400, shade_300, shade_200, shade_100];

  const chartValues: ChartValue[] = sections.map((data, index) => ({
    name: data.Section.name,
    shortName: truncate(data.Section.section, 8),
    value: data.violations_count,
    fill: COLOR_SHADES[index % COLOR_SHADES.length]
  }));

  if (loading || props.isLoading) {
    return <BarChartSkeleton numberOfBars={15} maxBarHeight={150} />;
  }

  return <SimpleBarChart data={chartValues} aspect={3} />;
};

export default SectionsChartContainer;
