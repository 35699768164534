import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headerWrapper: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  imageContainer: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    width: 200,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
      margin: 'auto'
    }
  }
}));
