import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  barChart: {
    marginBottom: theme.spacing(10),
    marginLeft: theme.spacing(-2),
    '& .recharts-surface': {
      overflow: 'visible'
    }
  }
}));
