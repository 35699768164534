import React from 'react';
import { Tabs, Tab } from '@material-ui/core';

import TabsSkeleton from 'components/skeletons/tabs-skeleton';

import { Tab as ITab } from 'interfaces/tabs-wrapper';

import useStyles from './tabs-wrapper.styles';

interface IProps<T> {
  value: T;
  tabs: ITab<T>[];
  isLoading?: boolean;
  onChange: (value: T) => void;
}

function TabsWrapper<T>(props: IProps<T>) {
  const classes = useStyles();

  if (props.isLoading && props.tabs.length === 0) {
    return <TabsSkeleton />;
  }

  return (
    <Tabs
      scrollButtons="auto"
      variant="scrollable"
      indicatorColor="primary"
      value={props.value}
      onChange={(_, value) => props.onChange(value)}
    >
      {props.tabs.map((tab, index) => (
        <Tab
          classes={{ root: classes.tabRoot }}
          label={tab.label}
          value={tab.value}
          key={`tab-${index}`}
        />
      ))}
    </Tabs>
  );
}

export default TabsWrapper;
