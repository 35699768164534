import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(1)}px solid ${theme.palette.tableBackground.main}`,
    cursor: 'pointer',
    padding: theme.spacing(5)
  },
  activeCard: {
    border: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`
  }
}));
