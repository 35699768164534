import React from 'react';
import { Typography, Box } from '@material-ui/core';

import TextChartSkeleton from 'components/skeletons/charts-skeleton/text-chart-skeleton';

import useStyles from './simple-text-chart.styles';

interface IProps {
  value: number;
  isLoading?: boolean;
}

const SimpleTextChart = (props: IProps) => {
  const classes = useStyles();
  if (props.isLoading) {
    return <TextChartSkeleton />;
  }

  return (
    <Box className={classes.textChartContainer}>
      <Typography variant="h3">
        <b>{props.value}</b>
      </Typography>
    </Box>
  );
};

export default SimpleTextChart;
