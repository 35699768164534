import React, { useState } from 'react';
import { TableCell, TableRow, Button, Collapse } from '@material-ui/core';

import ViolationsRowExpanded from '../violations-row-expanded';

import { Row, Alignment } from 'interfaces/table-interface';

import useStyles from './violations-row.styles';

interface IProps {
  row: Row<string>;
}

const ViolationsRow = ({ row }: IProps) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfColumns = row.values.length + 1;
  const centeredColumns = [4, 5];

  return (
    <>
      <TableRow>
        {row.values.map((value, i) => {
          const alignment = centeredColumns.includes(i) ? Alignment.center : Alignment.left;

          return (
            <TableCell align={alignment} key={i}>
              {value}
            </TableCell>
          );
        })}
        <TableCell align="right">
          <Button
            className={classes.expandBtn}
            variant="contained"
            color="secondary"
            onClick={() => setExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide' : 'View'} assets
          </Button>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expandedRow}>
        <TableCell colSpan={numberOfColumns} className={classes.expandedCell}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <ViolationsRowExpanded ruleId={row.ruleId} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ViolationsRow;
