import React, { useEffect } from 'react';

import CreateCustomRuleDialog from 'components/dialogs/create-custom-rule-dialog';
import ConfirmDeleteDialog from 'components/dialogs/confirm-delete-dialog';

import eventEmitter, { Events, DialogEventKeys } from 'redux/event-factory';

import { DialogTypes, DialogComponentProps } from 'interfaces/dialog-interface';

function DialogContainer<T>(props: DialogComponentProps) {
  const getDialogComponent = (type: keyof typeof DialogTypes) => {
    switch (type) {
      case 'CREATE_CUSTOM_RULE':
        return CreateCustomRuleDialog;
      case 'CONFIRM_DELETE':
        return ConfirmDeleteDialog;
    }
  };

  useEffect(() => {
    return () => {
      eventEmitter.removeAllListeners(Events.DialogEvent, DialogEventKeys.CONFIRM);
    };
  }, []);

  const onConfirm = (values?: T) => {
    eventEmitter.emit<T>(Events.DialogEvent, DialogEventKeys.CONFIRM, values);
    props.onClose();
  };

  const DialogComponent = getDialogComponent(props.type);

  if (!DialogComponent) {
    return null;
  }

  return <DialogComponent {...props} onConfirm={onConfirm} />;
}

export default DialogContainer;
