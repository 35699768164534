import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;
const closedDrawerWidth = 60;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#FBFEFD",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: closedDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    overflowX: "hidden",
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
  },
  content: {
    position: "relative",
    minHeight: "100vh",
    paddingBottom: theme.spacing(30),
    flexGrow: 1,
    padding: theme.spacing(12),
    width: "calc(100% - 60px)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(30),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: closedDrawerWidth,
      padding: theme.spacing(5),
      paddingBottom: theme.spacing(39),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: closedDrawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 0,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  drawerListItem: {
    opacity: 0.5,
  },
  drawerListItemActive: {
    opacity: 1,
  },
  bottomListContainer: {
    marginTop: "auto",
  },
  logo: {
    marginLeft: theme.spacing(-4),
  },
  logoListItem: {
    marginBottom: theme.spacing(10),
    alignItems: "flex-start",
  },
  footer: {
    maxWidth: "95%",
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(10, 0),
  },
}));
