import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expandedRow: {
    backgroundColor: theme.palette.alto.main
  },
  expandedCell: {
    padding: 0
  },
  expandBtn: {
    whiteSpace: 'nowrap'
  }
}));
