import get from 'lodash/get';
import { FormikTouched, FormikErrors } from 'formik';

export const getInputError = (
  field: string,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>
) => {
  const error = get(errors, field, undefined);
  const isTouched = get(touched, field, false);

  if (!error || !isTouched) {
    return {};
  }

  return {
    error: true,
    status: 'danger',
    helperText: error
  };
};
