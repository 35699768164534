import React from 'react';
import groupBy from 'lodash/groupBy';
import { FormControl, Select, MenuItem, InputLabel, ListSubheader } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ComplianceSection } from 'interfaces/compliance-interface';

import useStyles from './select-control.styles';

export type SelectControlProps = {
  value: ComplianceSection;
  values: ComplianceSection[];

  onChange: (section_id: string) => void;
};

const SelectControl = (props: SelectControlProps) => {
  const classes = useStyles();
  const groupedValues = groupBy(props.values, (option) => option.Section.function);

  const renderGroupValues = (groupName: string) => {
    return [
      <ListSubheader key={groupName} color="primary">
        {groupName !== 'undefined' && groupName}
      </ListSubheader>,
      groupedValues[groupName].map((option) => {
        const noViolations = option.violations_count === 0;

        return (
          <MenuItem
            className={noViolations ? classes.disabledMenuItem : undefined}
            value={option.Section.node_id}
            key={option.Section.node_id}
          >
            {option.Section.name}
          </MenuItem>
        );
      })
    ];
  };

  return (
    <FormControl variant="outlined" classes={{ root: classes.formControl }}>
      <InputLabel>Select Section</InputLabel>
      <Select
        label="Select Section"
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        value={props.value.Section.node_id}
        onChange={(e) => props.onChange(e.target.value as string)}
      >
        {Object.keys(groupedValues).map((groupName) => renderGroupValues(groupName))}
      </Select>
    </FormControl>
  );
};
export default SelectControl;
