import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, Hidden } from '@material-ui/core';

const TableSkeleton = () => {
  return (
    <Grid container spacing={10}>
      <Grid item xs={4} sm={1}>
        <Skeleton animation="wave" />
      </Grid>

      <Grid item xs={4} sm={1}>
        <Skeleton animation="wave" />
      </Grid>

      <Grid item xs={4} sm={1}>
        <Skeleton animation="wave" />
      </Grid>

      <Hidden xsDown>
        <Grid item sm={1}>
          <Skeleton animation="wave" />
        </Grid>

        <Grid item sm={1}>
          <Skeleton animation="wave" />
        </Grid>

        <Grid item sm={1}>
          <Skeleton animation="wave" />
        </Grid>

        <Grid item sm={1}>
          <Skeleton animation="wave" />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default TableSkeleton;
