import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  descriptionCell: {
    minWidth: theme.spacing(120)
  },
  viewAssetsBtn: {
    whiteSpace: 'nowrap',
    textTransform: 'none'
  },
  expansionRow: {
    backgroundColor: theme.palette.tableBackground.main
  },
  expansionCell: {
    padding: 0,
    border: 'none'
  }
}));
