import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, LinearProgress } from "@material-ui/core";

import { fetchAccounts } from "redux/features/settings-store";
import { fetchRegions } from "redux/features/inventory-store";
import { getHelp } from "redux/features/ui-store";

import TabsWrapper from "components/tabs-wrapper";
import ProfileSettingsAccounts from "./profile-settings-accounts";
import ProfileSettingsIntegrations from "./profile-settings-integrations";
import ProfileSettingsNewAccount from "./profile-settings-accounts/profile-settings-new-account";

import { AccountResponse } from "interfaces/settings-interface";
import { AppState } from "interfaces/app-state-interface";
import { InventoryRegionData } from "interfaces/inventory-interface";

const tabs = [
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Integrations",
    value: "integrations",
  },
];

const ProfileSettings = () => {
  //state
  const [currentTab, setCurrentTab] = useState("accounts");
  const [isLoading, setLoading] = useState(true);

  //redux
  const dispatch = useDispatch();
  const allAccounts = useSelector<AppState, AccountResponse[]>(
    (state) => state.settings.accounts
  );
  const regions = useSelector<AppState, InventoryRegionData[]>(
    (state) => state.inventory.regions
  );

  const accounts = currentTab === "accounts";
  const integrations = currentTab === "integrations";

  const onPageInit = async () => {
    if (accounts) {
      setLoading(true);
      await dispatch(fetchAccounts());
      await dispatch(getHelp("settings/accountGrid"));
      setLoading(false);
      if (regions.length === 0) {
        await dispatch(fetchRegions());
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (isLoading) {
    return <LinearProgress />;
  }

  const renderAccounts = () => {
    return (
      <Grid container spacing={10}>
        {allAccounts.map((account, index) => (
          <Grid item xs={12} key={`${account.account}-${index}`}>
            <ProfileSettingsAccounts
              accountData={account}
              allRegions={regions}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ProfileSettingsNewAccount />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justify="center">
      <Grid item xs={9}>
        <TabsWrapper value={currentTab} tabs={tabs} onChange={setCurrentTab} />

        <Box my={10} />

        {accounts && renderAccounts()}
        {integrations && <ProfileSettingsIntegrations />}
      </Grid>
    </Grid>
  );
};

export default ProfileSettings;
