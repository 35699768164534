import { useSelector } from 'react-redux';

import { AppState } from 'interfaces/app-state-interface';
import { InventoryRegionData } from 'interfaces/inventory-interface';
import { Marker } from 'interfaces/world-map-chart-interface';

const useInventoryRegions = (): Marker[] => {
  const regions = useSelector<AppState, InventoryRegionData[]>((state) => state.inventory.regions);

  const mapChartMarkers: Marker[] = regions.map((region) => {
    let summaryCount = 0;

    if (region.summary) {
      summaryCount = region.summary.reduce(
        (prevSummary, currentSummary) => prevSummary + currentSummary.count,
        0
      );
    }

    return {
      coordinates: [Number(region.location.longitude), Number(region.location.latitude)],
      summaryCount,
      region: region.name,
      regionArea: region.area
    };
  });

  return mapChartMarkers;
};

export default useInventoryRegions;
