import React, { useState } from "react";
import clsx from "clsx";
import lodash from "lodash";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
  InputLabel,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import {
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import {
  saveSettings,
  testConnection,
  scanAccounts,
  downloadAccount,
  deleteAccount,
} from "redux/features/settings-store";
import { getInputError } from "utils/form-utils";

import SectionWrapper from "components/section-wrapper";

import { SettingsFields, AccountResponse } from "interfaces/settings-interface";
import { InventoryRegionData } from "interfaces/inventory-interface";

import useStyles from "./profile-settings-accounts.styles";

interface IProps {
  accountData: AccountResponse;
  allRegions: InventoryRegionData[];
}
const ProfileSettingsAccounts = ({ accountData, allRegions }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isConnectionLoading, setConnectionLoading] = useState(false);

  let {
    name,
    account,
    role,
    regions,
    externalid,
    notes,
    active,
    connection,
  } = accountData;

  let connectionIcon: JSX.Element | null =
    connection === "success" ? <CheckIcon /> : <CloseIcon color="error" />;
  if (connection === undefined) {
    connectionIcon = null;
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    dirty,
  } = useFormik<SettingsFields>({
    initialValues: {
      name,
      account,
      role,
      regions,
      externalid,
      notes,
      active,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      account: Yup.string().required(),
      role: Yup.string().required(),
      regions: Yup.string().required(),
      externalid: Yup.string(),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch(saveSettings(values));
    },
  });

  const onTestConnectionClick = async () => {
    setConnectionLoading(true);
    await dispatch(testConnection(values.account));
    setConnectionLoading(false);
  };

  return (
    <SectionWrapper title="" helpIndex="settings/accountGrid">
      <Grid container justify="center">
        <Grid item xs={11}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.formTitle}>
                  Account Nick Name:
                </Typography>
                <TextField
                  name="name"
                  variant="outlined"
                  value={values.name}
                  fullWidth
                  onChange={handleChange}
                  {...getInputError("name", touched, errors)}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" className={classes.formTitle}>
                  Account #: *
                </Typography>
                <TextField
                  name="account"
                  variant="outlined"
                  fullWidth
                  value={values.account}
                  onChange={handleChange}
                  {...getInputError("account", touched, errors)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.formTitle}>
                  Role: *
                </Typography>
                <TextField
                  name="role"
                  variant="outlined"
                  fullWidth
                  value={values.role}
                  onChange={handleChange}
                  {...getInputError("role", touched, errors)}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" className={classes.formTitle}>
                  Regions: *
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Select region</InputLabel>
                  <Select
                    multiple
                    value={values.regions}
                    onChange={handleChange}
                    label="Select region"
                    name="regions"
                  >
                    {allRegions.map((region) => (
                      <MenuItem key={region.name} value={region.name}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box mt={3} />

            <Grid item xs={12}>
              <Typography variant="body2" className={classes.formTitle}>
                External Id (Optional):
              </Typography>
              <TextField
                name="externalid"
                variant="outlined"
                fullWidth
                value={values.externalid}
                onChange={handleChange}
                {...getInputError("externalid", touched, errors)}
              />
            </Grid>

            <Box mt={3} />

            <Typography variant="body2" className={classes.formTitle}>
              Notes:
            </Typography>
            <TextField
              name="notes"
              variant="outlined"
              fullWidth
              multiline
              value={values.notes}
              rows={4}
              onChange={handleChange}
              {...getInputError("notes", touched, errors)}
            />

            <FormControlLabel
              label={values.active ? "Active" : "Inactive"}
              labelPlacement="top"
              className={classes.activeSwitch}
              control={
                <Switch
                  checked={values.active}
                  onChange={handleChange}
                  name="active"
                />
              }
            />

            <Box display="flex" mt={7} className={classes.toolsContainer}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.button}
                disabled={!(dirty && lodash.isEmpty(errors))}
              >
                Save
              </Button>

              <Box className={classes.actionsContainer}>
                <Button
                  variant="contained"
                  className={clsx(classes.button, classes.testBtn)}
                  disabled={(dirty)}
                  disableRipple
                  disableFocusRipple
                  onClick={onTestConnectionClick}
                  endIcon={
                    isConnectionLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      connectionIcon
                    )
                  }
                >
                  Test Connection
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => dispatch(scanAccounts(values.account))}
                >
                  Scan Now
                </Button>

                <Button
                  variant="contained"
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  className={clsx(classes.button, classes.deleteBtn)}
                  onClick={() => dispatch(deleteAccount(values.account))}
                >
                  Delete
                </Button>
              </Box>

              <Button
                variant="contained"
                color="secondary"
                className={clsx(classes.button, classes.downloadBtn)}
                onClick={() =>
                  dispatch(downloadAccount(values.account, "json"))
                }
              >
                Download
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default ProfileSettingsAccounts;
