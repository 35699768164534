import { makeStyles } from '@material-ui/core/styles';

interface ImageProps {
  width?: number | string;
  height?: number | string;
}

export default makeStyles((theme) => ({
  noImageIcon: (props: ImageProps) => ({
    opacity: 0.3,
    margin: 'auto',
    width: props.width,
    height: props.height
  })
}));
