import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tabRoot: {
    minWidth: theme.spacing(52),
    opacity: 0.6,
    fontWeight: 'bold',
    marginRight: theme.spacing(10),
    color: theme.palette.text.primary
  }
}));
