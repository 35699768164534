import React from 'react';
import lodash from 'lodash';
import { Paper, Typography, Box } from '@material-ui/core';
import { TooltipProps } from 'recharts';

const VerticalComposedChartTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload) {
    return (
      <Paper>
        <Box padding={5}>
          {payload.map((chartValue) => {
            const name = lodash.startCase(chartValue.name);

            return (
              <Typography style={{ color: chartValue.color }} variant="body1" key={chartValue.name}>
                {name}: {chartValue.value} days
              </Typography>
            );
          })}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default VerticalComposedChartTooltip;
