import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";

import { getInputError } from "utils/form-utils";

import { IntegrationType } from "interfaces/settings-interface";

import useStyles from "./integrations-form.styles";

interface IProps {
  onSubmit: (integrationData: IntegrationType) => void;
  integrationFields: IntegrationType;
}

const IntegrationsForm = (props: IProps) => {
  const classes = useStyles();

  const { connected, ...formFields } = props.integrationFields;

  const fieldNames = Object.keys(formFields);

  const validationSchema = fieldNames.map((field) => [
    field,
    Yup.string().required(),
  ]);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    dirty,
  } = useFormik<IntegrationType>({
    initialValues: {
      ...formFields,
    },
    validationSchema: Yup.object().shape(Object.fromEntries(validationSchema)),
    enableReinitialize: true,
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justify="center" alignItems="center">
        {fieldNames.map((name) => {
          const isSecretField = connected && values[name] === "";

          if (name === "active") {
            return null;
          }

          return (
            <Grid key={name} container justify="center" alignItems="center">
              <Grid item sm={12} md={6}>
                <Typography variant="body2">{name}:</Typography>
                <TextField
                  size="small"
                  name={name}
                  variant="outlined"
                  fullWidth
                  type={isSecretField ? "password" : "text"}
                  value={isSecretField ? "" : values[name]}
                  onChange={handleChange}
                  {...getInputError(name, touched, errors)}
                />
              </Grid>
            </Grid>
          );
        })}

        <Grid item xs={6}>
          <FormControlLabel
            label={values.active ? "Active" : "Inactive"}
            labelPlacement="top"
            className={classes.activeSwitch}
            control={
              <Switch
                checked={!!values.active}
                onChange={handleChange}
                name="active"
              />
            }
          />
        </Grid>

        <Box mt={13} width="100%" />

        <Button
          type="submit"
          variant="contained"
          disableElevation
          color="primary"
          className={classes.connectBtn}
          disabled={!dirty}
        >
          Save
        </Button>
      </Grid>
    </form>
  );
};

export default IntegrationsForm;
