import React, { useState, useRef } from "react";
import {
  Button,
  Box,
  Paper,
  MenuItem,
  Popper,
  ClickAwayListener,
  Grid,
  Tooltip,
} from "@material-ui/core";

import {
  FindInPage as FindInPageIcon,
  ExpandMore as ExpandMoreIcon,
  Description as DescriptionIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";

import SearchInput from "components/search-input";

import useStyles from "../section-wrapper.styles";

export interface IProps {
  toolsPosition?: "flex-start" | "flex-end";
  defaultVisibility?: boolean;
  visibilityTooltipText?: string;
  scanBtnTitle?: string;
  onScan?: () => void;
  onSearch?: (text: string) => void;
  onDownloadXLS?: () => void;
  onDownloadJSON?: () => void;
  onToggleVisibility?: (visible: boolean) => void;
  onCreateCustomRule?: () => void;
}

const SectionToolsWrapper = (props: IProps) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(props.defaultVisibility || false);
  const visibilityTooltipInfo = props.visibilityTooltipText
    ? props.visibilityTooltipText
    : "Show only controls with gaps";

  const onChangeVisibility = () => {
    if (!props.onToggleVisibility) {
      return;
    }

    props.onToggleVisibility(!isVisible);
    setVisible(!isVisible);
  };

  return (
    <div className={classes.headerWrapper}>
      <Grid container justify={props.toolsPosition} spacing={3}>
        <Grid item sm={4} xs={12}>
          {props.onSearch && <SearchInput onChangeText={props.onSearch} />}
        </Grid>
        <Grid item>
          {props.onToggleVisibility && (
            <Tooltip title={visibilityTooltipInfo} placement="top">
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={onChangeVisibility}
              >
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Button>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {props.onCreateCustomRule && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              onClick={props.onCreateCustomRule}
            >
              Create custom rule
            </Button>
          )}
        </Grid>
        <Grid item>
          {props.onScan &&
            (props.scanBtnTitle ? (
              <Tooltip
                placement="top"
                title="Re-evaluates the miscofiguration policies against the system state. The backend system state is updated near-real time."
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={props.onScan}
                >
                  {props.scanBtnTitle}
                </Button>
              </Tooltip>
            ) : (
              <Button
                className={classes.button}
                variant="contained"
                disableElevation
                color="primary"
                onClick={props.onScan}
              >
                Scan Now
              </Button>
            ))}
        </Grid>
        <Grid item>
          {(props.onDownloadXLS || props.onDownloadJSON) && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  ref={anchorRef}
                  onClick={() => setOpen(!isOpen)}
                  endIcon={<ExpandMoreIcon fontSize="small" />}
                >
                  Download
                </Button>

                <Popper open={isOpen} anchorEl={anchorRef.current}>
                  <Box mt={3}>
                    <Paper>
                      {props.onDownloadXLS && (
                        <MenuItem onClick={props.onDownloadXLS}>
                          <DescriptionIcon fontSize="small" />
                          <Box ml={3}>Download.xls</Box>
                        </MenuItem>
                      )}

                      {props.onDownloadJSON && (
                        <MenuItem onClick={props.onDownloadJSON}>
                          <FindInPageIcon fontSize="small" />
                          <Box ml={3}>Download.json</Box>
                        </MenuItem>
                      )}
                    </Paper>
                  </Box>
                </Popper>
              </>
            </ClickAwayListener>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SectionToolsWrapper.defaultProps = {
  toolsPosition: "flex-end",
};
export default SectionToolsWrapper;
