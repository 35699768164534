import React from 'react';
import LayoutWrapper from './components/layout-wrapper';

interface IProps {
  children: React.ReactNode;
}

const LayoutContainer = (props: IProps) => {
  return <LayoutWrapper>{props.children}</LayoutWrapper>;
};

export default LayoutContainer;
