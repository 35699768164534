import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { listToken, setIsTokenValid } from "redux/features/auth-store";
import { getCode } from "utils/auth-utils";

const useAuthToken = () => {
  //redux
  const dispatch = useDispatch();

  const onPageInit = async () => {
    dispatch(setIsTokenValid(await isTokenValid()));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const isTokenValid = async (): Promise<boolean> => {
    const token = localStorage.getItem("token");

    if (token === null) {
      const code = getCode();

      if (code) {
        await dispatch(listToken(code));
      } else {
        return false;
      }
    }

    return true;
  };
};

export default useAuthToken;
