import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableCell, TableRow } from '@material-ui/core';

interface IProps {
  columns: number;
  rows: number;
}

const TableSkeleton = (props: IProps) => {
  return (
    <>
      {[...Array(props.rows)].map((_, rowIndex) => (
        <TableRow key={`skeleton-row-${rowIndex}`}>
          {[...Array(props.columns)].map((_, columnIndex) => (
            <TableCell key={`skeleton-column-${columnIndex}`}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
