import React, { useState } from "react";
import clsx from "clsx";
import { TableRow, TableCell, Collapse, IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import InventoryTableRowExpanded from "./inventory-table-row-expanded";

import { Row } from "interfaces/table-interface";

import useStyles from "./inventory-table-row.styles";

interface IProps<T, K> {
  row: Row<T, K>;
}

function InventoryTableRow<T, K>(props: IProps<T, K>) {
  const classes = useStyles();

  // state
  const [isExpanded, setExpanded] = useState(false);

  // redux
  const currentArn = String(props.row.values[props.row.values.length - 1]);

  // +1 because we have a column for expandIcon
  const numberOfColumns = props.row.values.length + 1;

  return (
    <>
      <TableRow>
        {props.row.values.map((rowCell, k) => {
          const isAssetCell = k === numberOfColumns - 2;

          return (
            <TableCell
              size="small"
              key={`row-value-${k}`}
              className={clsx(classes.tableCell, {
                [classes.assetCell]: isAssetCell,
              })}
            >
              {rowCell}
            </TableCell>
          );
        })}
        <TableCell size="small" align="right">
          <IconButton
            className={classes.expandButton}
            size="small"
            onClick={() => setExpanded(!isExpanded)}
          >
            <ExpandMoreIcon
              className={clsx({
                [classes.rotateIcon]: isExpanded,
              })}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expansionRow}>
        <TableCell
          size="small"
          colSpan={numberOfColumns}
          className={classes.expansionCell}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <InventoryTableRowExpanded arn={currentArn} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryTableRow;
