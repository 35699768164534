import React from 'react';
import { TooltipProps, TooltipPayload } from 'recharts';
import { Paper, Box, Typography } from '@material-ui/core';

const ViolationSummaryChartTooltip = ({ active, payload }: TooltipProps) => {
  if (active && payload && payload[0].payload) {
    const reversedPayload = (payload as TooltipPayload[]).reverse();
    const violationName = payload[0].payload.name;

    return (
      <Paper>
        <Box padding={5}>
          <Typography variant="body1">{violationName}</Typography>
          {reversedPayload.map((bar) => (
            <Typography style={{ color: bar.fill }} variant="body2" key={bar.name}>
              {bar.name}: {bar.value}
            </Typography>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default ViolationSummaryChartTooltip;
