import React, { useState } from "react";
import clsx from "clsx";
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Create as CreateIcon,
} from "@material-ui/icons";

import ActiveTableRowExpanded from "./active-table-row-expanded";
import ActiveRulesSwitch from "../active-rules-switch";

import { Row } from "interfaces/table-interface";
import { IExpandedRule } from "interfaces/active-rules-interface";

import useStyles from "./active-rules-table-row.styles";

interface IProps {
  row: Row<string | boolean, IExpandedRule>;
  handleActivate: (id: string, activate: boolean) => void;
  handleEdit: (id: string) => void;
}

function ActiveRulesTableRow({ row, handleActivate, handleEdit }: IProps) {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfColumns = row.values.length;
  const isChecked = row.values[numberOfColumns - 1] as boolean;

  return (
    <>
      <TableRow>
        {row.values.map((rowCell, k) => {
          const riskColumnWidth = k === numberOfColumns - 2 ? 200 : "initial";
          const lastColumn = numberOfColumns - 1;

          if (k === lastColumn) {
            return null;
          }

          return (
            <TableCell size="small" key={`row-value-${k}`}>
              <Box width={riskColumnWidth}>{rowCell}</Box>
            </TableCell>
          );
        })}

        <TableCell size="small" align="right">
          <Box display="flex">
            <ActiveRulesSwitch
              handleActivate={(activate) =>
                handleActivate(row.ruleId, activate)
              }
              isChecked={isChecked}
            />

            <IconButton size="small" onClick={() => handleEdit(row.ruleId)}>
              <CreateIcon fontSize="small" />
            </IconButton>

            <IconButton
              className={classes.expandButton}
              size="small"
              onClick={() => setExpanded(!isExpanded)}
            >
              <ExpandMoreIcon
                className={clsx({
                  [classes.rotateIcon]: isExpanded,
                })}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow className={classes.expansionRow}>
        <TableCell
          size="small"
          colSpan={numberOfColumns}
          className={classes.expansionCell}
        >
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <ActiveTableRowExpanded expanded={row.expanded} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default ActiveRulesTableRow;
