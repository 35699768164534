import { Row } from './table-interface';

export interface ViolationRuleProperties {
  last_executed_on: string;
  platform: string;
  severity: string;
  remediation: string;
  [key: string]: string;
}

export interface ViolationNode {
  nodes: {
    account: string;
    key: string;
    label: string;
    region: string;
    properties: { node_id: string };
  }[];
  suppressed: boolean;
}

export interface NodeData {
  ruleId: string;
  asset: string;
  suppressStatus: boolean;
}

export interface Violation {
  resources: ViolationNode[];
  rule: {
    description: string;
    name: string;
    rule_id: string;
    service: string;
    last_executed_on: string;
    platform: string;
    severity: string;
    remediation: string;
    properties: ViolationRuleProperties;
  };
}

export interface ViolationResponse {
  misconfig: Violation[];
}

export interface ViolationDetails {
  assetsDetails: Row<string>[];
  description: string;
  remediation: string;
}

export enum Severity {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium'
}

export interface Summary {
  count: number;
  severity: Severity;
}

export interface DateSummary {
  date: string;
  summary: Summary[];
}

export interface SummaryByDate {
  date_summary: DateSummary[];
  severity_count: Summary[];
}

export interface SummaryByService {
  service: string;
  summary: Summary[];
}

export interface SummaryByRemediationAge {
  severity: string;
  combined_average?: number;
  open_violations_age?: number;
  remediation_age?: number;
  total_open_violations?: number;
  total_remediation?: number;
}

export enum ViolationSummaryType {
  byDate = 'summaryByDate',
  byService = 'summaryByService'
}
