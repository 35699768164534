import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  connectBtn: {
    textTransform: "none",
    padding: theme.spacing(5, 15),
    marginRight: theme.spacing(40),
  },
  slightTopMargin: {
    marginTop: theme.spacing(5),
  },
  activeSwitch: {
    margin: 0,
  },
}));
