import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  onPageInit,
  changeType,
  changeActive,
  editCustomRule,
  createCustomRule,
  deleteCustomRule,
} from "redux/features/active-rules-store";
import { createDialog } from "redux/features/notifications-store";

import TabsWrapper from "components/tabs-wrapper";
import TableWrapper from "components/table-wrapper";
import SectionWrapper from "components/section-wrapper";
import ActiveRulesTableRow from "./components/default-rules-table/active-rules-table-row";
import CustomRulesTableRow from "./components/custom-rules-table/custom-rules-table-row";

import useActiveRules from "hooks/useActiveRules";

import { Tab } from "interfaces/tabs-wrapper";
import { RuleType, Rule, CreateCustomRule } from "interfaces/rule-interface";
import { Row } from "interfaces/table-interface";
import { IExpandedRule } from "interfaces/active-rules-interface";
import { AppState } from "interfaces/app-state-interface";
import { TextProps } from "components/dialogs/create-custom-rule-dialog/create-custom-rule-dialog";

const tabs: Tab<RuleType>[] = [
  {
    label: "Active rules",
    value: "default",
  },
  {
    label: "Custom rules",
    value: "custom",
  },
];

const ActiveRules = () => {
  const [search, setSearch] = useState<string>("");
  const rules = useSelector<AppState, Rule[]>(
    (state) => state.activeRules.rules
  );
  const ruleType = useSelector<AppState, RuleType>(
    (state) => state.activeRules.type
  );
  const isLoading = useSelector<AppState, boolean>(
    (state) => state.ui.isLoading
  );
  const [columns, rows] = useActiveRules(rules, ruleType);
  const dispatch = useDispatch();

  const isCustom = ruleType === "custom";

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(onPageInit());
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onActivate = (id: string, active: boolean) => {
    dispatch(changeActive({ id, active }));
  };

  const onCreateCustomRule = () => {
    dispatch(
      createDialog({
        type: "CREATE_CUSTOM_RULE",
        onConfirm: (values: CreateCustomRule) =>
          dispatch(createCustomRule(values)),
      })
    );
  };

  const onEditCustomRule = (id: string, disabledFields: string[] = []) => {
    const currentRule = rules.find((rule) => rule.rule_id === id);

    if (!currentRule) {
      return;
    }

    const defaultValues: CreateCustomRule = {
      platform: currentRule.platform,
      service: currentRule.service,
      name: currentRule.name,
      rule_id: currentRule.rule_id,
      description: currentRule.description,
      remediation: currentRule.remediation,
      query: currentRule.query,
      is_active: currentRule.is_active,
      severity: currentRule.severity,
      properties: currentRule.properties,
    };

    dispatch(
      createDialog<TextProps, CreateCustomRule>({
        type: "CREATE_CUSTOM_RULE",
        textProps: {
          title: "Edit rule",
          submitButton: "Save",
          cancelButton: "Cancel",
        },
        defaultValues,
        disabledFields,
        onConfirm: (values: CreateCustomRule) =>
          dispatch(editCustomRule(values)),
      })
    );
  };

  const onEditActiveRule = (id: string) => {
    const disabledFields = [
      "platform",
      "service",
      "name",
      "rule_id",
      "query",
      "properties",
    ];
    return onEditCustomRule(id, disabledFields);
  };

  const onDeleteCustomRule = (id: string) => {
    dispatch(
      createDialog({
        type: "CONFIRM_DELETE",
        onConfirm: () => dispatch(deleteCustomRule(id)),
      })
    );
  };

  const onChangeType = (value: RuleType) => {
    dispatch(changeType(value));
  };

  const renderRow = (row: Row<string | boolean, IExpandedRule>, k: number) => {
    if (isCustom) {
      return (
        <CustomRulesTableRow
          key={`row-${row.ruleId}`}
          row={row}
          handleActivate={onActivate}
          handleEdit={onEditCustomRule}
          handleDelete={onDeleteCustomRule}
        />
      );
    }

    return (
      <ActiveRulesTableRow
        key={`row-${k}`}
        row={row}
        handleActivate={onActivate}
        handleEdit={onEditActiveRule}
      />
    );
  };

  return (
    <div>
      <TabsWrapper tabs={tabs} value={ruleType} onChange={onChangeType} />

      <Box my={10} />

      <Grid container spacing={10} justify="flex-start">
        <Grid item xs={12}>
          <SectionWrapper
            title=""
            helpIndex={isCustom ? "rules/customTable" : "rules/defaultTable"}
            onSearch={setSearch}
            toolsPosition="flex-start"
            onCreateCustomRule={isCustom ? onCreateCustomRule : undefined}
          >
            <TableWrapper
              search={search}
              columns={columns}
              rows={rows}
              isLoading={isLoading}
              renderRow={renderRow}
            />
          </SectionWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ActiveRules;
