import React from 'react';
import { Box } from '@material-ui/core';

import CollapsibleText from 'components/collapsible-text';

import useStyles from './selected-control-description.styles';

export type SelectedControlDescriptionProps = {
  description?: string;
};

const SelectedControlDescription: React.FC<SelectedControlDescriptionProps> = (props) => {
  const classes = useStyles();

  if (!props.description) {
    return null;
  }

  return (
    <Box mt={6} maxWidth="80%">
      <b>Selected control description:</b>

      <br />
      <Box mt={4} />

      <CollapsibleText
        text={props.description}
        maxLength={500}
        className={classes.selectedDescription}
      />
    </Box>
  );
};

export default SelectedControlDescription;
