import React from 'react';
import { Grid } from '@material-ui/core';

import useArnResource from 'hooks/useArnResource';

import InventoryTableRowSection from './inventory-table-row-section';
import InventoryExpandedRowSkeleton from 'components/skeletons/inventory-expanded-row-skeleton';

interface IProps {
  arn: string;
}

const InventoryTableRowExpanded = (props: IProps) => {
  const {
    data: [tags, attributes],
    loading
  } = useArnResource(props.arn);

  if (loading) {
    return <InventoryExpandedRowSkeleton />;
  }

  return (
    <Grid container>
      <InventoryTableRowSection title="Tags" tags={tags} />
      <InventoryTableRowSection title="Attributes" attributes={attributes} />
    </Grid>
  );
};

export default InventoryTableRowExpanded;
