import { Rule, RuleProperties, CreateCustomRule } from 'interfaces/rule-interface';

export const transformCustomRuleToRule = (customRule: CreateCustomRule) => {
  const { description, name, query, rule_id, service,  
          is_active, platform, severity, remediation } = customRule;
  const { properties, ...remainingRules } = customRule;

  const rule: Rule = {
    description,
    name,
    query,
    rule_id,
    service,
    is_active, 
    platform, 
    severity, 
    remediation, 
    properties: { ...remainingRules } as RuleProperties
  };

  return rule;
};
