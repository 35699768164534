import { AxiosPromise } from "axios";

import httpService from "./http-service";

import {
  SummaryByDate,
  SummaryByService,
  SummaryByRemediationAge,
  ViolationResponse,
} from "interfaces/violations-interface";
import { DownloadFormat } from "interfaces/download-interface";

interface Response<T> {
  response: T;
}

export const listViolations = (
  suppress?: boolean
): AxiosPromise<ViolationResponse> => {
  if (suppress === undefined) {
    return httpService.get(`/aws/violations`);
  }

  return httpService.get(`/aws/violations`, {
    params: {
      "include-suppressed": suppress,
    },
  });
};

export const listSummaryByDate = (): AxiosPromise<SummaryByDate> => {
  return httpService.get("/aws/violations/summary-by-date");
};

export const listSummaryByService = (): AxiosPromise<
  Response<SummaryByService[]>
> => {
  return httpService.get("/aws/violations/summary-by-service");
};

export const listSummaryByRemediationAge = (): AxiosPromise<
  Response<SummaryByRemediationAge[]>
> => {
  return httpService.get("/aws/violations/remediations-age");
};

export const scanViolations = (
  includeSuppressed: boolean
): AxiosPromise<ViolationResponse> => {
  return httpService.put(`/aws/violations`, {
    params: {
      "include-suppressed": includeSuppressed,
    },
  });
};

export const downloadViolations = (format: DownloadFormat) => {
  return httpService
    .get("/aws/violations/download", {
      params: {
        format,
      },
      responseType: "blob",
    })
    .then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "misconfig." + format);
      document.body.appendChild(link);
      link.click();
    });
};

export const listViolationsSuppress = (
  ruleId: string,
  nodeLabel: string,
  nodeId: string
) => {
  return httpService.put("aws/violations/suppress", {
      "rule_id": ruleId,
      "node_label": nodeLabel,
      "node_id": nodeId,
  });
};

export const listViolationsUnSuppress = (
  ruleId: string,
  nodeLabel: string,
  nodeId: string
) => {
  return httpService.put("aws/violations/un-suppress", {
      "rule_id": ruleId,
      "node_label": nodeLabel,
      "node_id": nodeId,
  });
};
