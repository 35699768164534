import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as ViolationsStore from "redux/features/violations-store";
import { truncate } from "utils/text-utils";

import {
  SummaryByDate,
  SummaryByService,
} from "interfaces/violations-interface";
import { AppState } from "interfaces/app-state-interface";
import {
  MultiLineChartValue,
  LineChartValues,
  ChartValue,
} from "interfaces/charts-interface";
import { FetchHook } from "interfaces/hooks-interface";

export const useViolationsSummary = (): FetchHook<MultiLineChartValue[]> => {
  const [loading, setLoading] = useState(false);

  const summaries = useSelector<AppState, SummaryByService[]>(
    (state) => state.violations.summaryByService
  );
  const dispatch = useDispatch();

  const chartValues: MultiLineChartValue[] = [];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(ViolationsStore.fetchSummaryByService());

    setLoading(false);
  };

  if (!summaries && !loading) {
    return {
      data: [],
      loading: false,
    };
  }

  for (let summary of summaries) {
    const name = summary.service;

    const severityType: Omit<MultiLineChartValue, "name"> = {};

    for (let item of summary.summary) {
      severityType[item.severity] = item.count;
    }

    chartValues.push({
      name,
      shortName: truncate(name, 4),
      ...severityType,
    });
  }

  return {
    data: chartValues,
    loading,
  };
};

export const useViolationSummaryByDate = (): FetchHook<LineChartValues> => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const summaries = useSelector<AppState, SummaryByDate>(
    (state) => state.violations.summaryByDate
  );

  const highSeverity = [];
  const mediumSeverity = [];
  const lowSeverity = [];

  let High = 0;
  let Medium = 0;
  let Low = 0;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(ViolationsStore.fetchSummaryByDate());

    setLoading(false);
  };

  if (!summaries && !loading) {
    return {
      data: {
        highSeverity: [],
        mediumSeverity: [],
        lowSeverity: [],
        High: 0,
        Medium: 0,
        Low: 0,
      },
      loading: false,
    };
  }

  for (let summary of summaries.date_summary) {
    const name = summary.date;

    for (let item of summary.summary) {
      if (item.severity === "High") {
        highSeverity.push({ name, value: item.count });
      } else if (item.severity === "Medium") {
        mediumSeverity.push({ name, value: item.count });
      } else if (item.severity === "Low") {
        lowSeverity.push({ name, value: item.count });
      }
    }
  }

  sortByDate(highSeverity);
  sortByDate(mediumSeverity);
  sortByDate(lowSeverity);

  for (let item of summaries.severity_count) {
    if (item.severity === "High") {
      High = item.count;
    } else if (item.severity === "Medium") {
      Medium = item.count;
    } else if (item.severity === "Low") {
      Low = item.count;
    }
  }

  return {
    data: { highSeverity, mediumSeverity, lowSeverity, High, Medium, Low },
    loading,
  };
};

const sortByDate = (dates: ChartValue[]) =>
  dates.sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());
