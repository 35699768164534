import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Paper, Tooltip, Box, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { UiSelectors } from 'redux/selectors';

import SimpleLineChart from 'components/charts/violations-summary-line-chart';

import { ChartValue } from 'interfaces/charts-interface';
import { HelpType } from 'interfaces/help-interface';

import useStyles from './violation-summary-chart-wrapper.styles';

interface IProps {
  title: string;
  severityCount?: number;
  textColor: string;
  helpIndex: HelpType;
  chartValues: ChartValue[];
  loading?: boolean;
}

const ViolationSummaryChartWrapper = (props: IProps) => {
  const classes = useStyles({
    color: props.textColor
  });

  const help = useSelector(UiSelectors.helpSelector);

  return (
    <Paper elevation={0} className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" className={classes.text}>
          {props.title}
        </Typography>
        <Tooltip title={help[props.helpIndex]} className={classes.tooltip}>
          <HelpIcon fontSize="small" />
        </Tooltip>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography variant="h3" className={clsx(classes.text, classes.severityCount)}>
          <b>{props.severityCount}</b>
        </Typography>

        <Box className={classes.chartContainer}>
          <SimpleLineChart
            values={props.chartValues}
            lineColor={props.textColor}
            isLoading={props.loading}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default ViolationSummaryChartWrapper;
