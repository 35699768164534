import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { AppState } from 'interfaces/app-state-interface';
import { ComplianceStandard } from 'interfaces/compliance-interface';
import { Tab } from 'interfaces/tabs-wrapper';

const complianceToTab = (compliance: ComplianceStandard | undefined): Tab<string> => {
  return {
    label: get(compliance, 'Standard.short_name', ''),
    value: get(compliance, 'Standard.node_id', '')
  };
};

type ReturnValue = [Tab<string>[], Tab<string>];

const useComplianceTabs = (): ReturnValue => {
  const standards = useSelector<AppState, ComplianceStandard[]>(
    (state) => state.compliance.standards
  );

  const activeStandard = useSelector<AppState, ComplianceStandard | undefined>(
    (state) => state.compliance.active_standard
  );

  const tabs: Tab<string>[] = useMemo(
    () => standards.map((standard) => complianceToTab(standard)),
    [standards]
  );

  const currentTab: Tab<string> = complianceToTab(activeStandard);

  return [tabs, currentTab];
};

export default useComplianceTabs;
