import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { setLoading, getHelp } from "./ui-store";
import { addSnackbar } from "./notifications-store";
import { setIsTokenValid } from "./auth-store";

import { transformCustomRuleToRule } from "utils/transform-interface-utils";
import * as ActiveRulesService from "services/active-rules-service";

import {
  RuleType,
  Rule,
  RuleProperties,
  CreateCustomRule,
} from "interfaces/rule-interface";
import { AppThunk } from "interfaces/app-thunk-interface";

interface ActivateRulePayload {
  id: string;
  active: boolean;
}

export interface ActiveRulesStore {
  type: RuleType;
  rules: Rule[];
}

const initialState: ActiveRulesStore = {
  type: "default",
  rules: [],
};

const slice = createSlice({
  name: "activeRules",
  initialState,
  reducers: {
    setType: (store: ActiveRulesStore, action: PayloadAction<RuleType>) => {
      store.type = action.payload;
    },
    addRule: (store: ActiveRulesStore, action: PayloadAction<Rule>) => {
      store.rules.push(action.payload);
    },
    setRules: (store: ActiveRulesStore, action: PayloadAction<Rule[]>) => {
      store.rules = action.payload;
    },
    setActive: (
      store: ActiveRulesStore,
      action: PayloadAction<ActivateRulePayload>
    ) => {
      const { rules } = store;
      const { id, active } = action.payload;
      const ruleIndex = rules.findIndex((rule) => rule.rule_id === id);

      if (ruleIndex > -1) {
        const currentRule = rules[ruleIndex];
        currentRule.is_active = active
      }
    },
    addCustomRule: (
      store: ActiveRulesStore,
      action: PayloadAction<CreateCustomRule>
    ) => {
      const rule = transformCustomRuleToRule(action.payload);
      store.rules.unshift(rule);
    },
    updateCustomRule: (
      store: ActiveRulesStore,
      action: PayloadAction<CreateCustomRule>
    ) => {
      const currentRuleIndex = store.rules.findIndex(
        (rule) => rule.rule_id === action.payload.rule_id
      );

      if (currentRuleIndex < 0) {
        return;
      }

      const { description, name, query, rule_id, service,
              is_active, platform, severity, remediation } = action.payload;
      const { properties, ...remainingRules } = action.payload;
      const updatedProperties = {
        ...properties,
        ...remainingRules,
      } as RuleProperties;

      store.rules[currentRuleIndex] = {
        ...store.rules[currentRuleIndex],
        description,
        name,
        properties: updatedProperties,
        query,
        rule_id,
        service,
        is_active, 
        platform, 
        severity, 
        remediation,
      };
    },
    deleteRule: (store: ActiveRulesStore, action: PayloadAction<string>) => {
      const currentRuleIndex = store.rules.findIndex(
        (rule) => rule.rule_id === action.payload
      );

      if (currentRuleIndex < 0) {
        return;
      }

      store.rules.splice(currentRuleIndex, 1);
    },
  },
});

export const onPageInit = (): AppThunk => async (dispatch, store) => {
  const rulesType = store().activeRules.type;

  dispatch(fetchRules(rulesType));
  dispatch(loadHelpText());
};

export const fetchRules = (type: RuleType): AppThunk => async (dispatch) => {
  if (localStorage.getItem("token")) {
    dispatch(setLoading(true));

    await ActiveRulesService.getRules(type)
      .then((res) => {
        dispatch(setRules(res.data.rules));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "An error occurred" }));
      });

    dispatch(setLoading(false));
  }
};

const loadHelpText = (): AppThunk => async (dispatch, store) => {
  const indexes = ["rules/defaultTable", "rules/customTable"];

  for (const index of indexes) {
    dispatch(getHelp(index));
  }
};

export const changeType = (type: RuleType): AppThunk => async (
  dispatch,
  store
) => {
  const prevType = store().activeRules.type;

  if (prevType === type) {
    return;
  }

  dispatch(setType(type));
  dispatch(fetchRules(type));
};

export const changeActive = (payload: ActivateRulePayload): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    const makeRequest = payload.active
      ? ActiveRulesService.activateRule
      : ActiveRulesService.deactivateRule;

    await makeRequest(payload.id)
      .then(() => {
        dispatch(setActive(payload));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "An error occurred" }));
      });
  }
};

export const createCustomRule = (payload: CreateCustomRule): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ActiveRulesService.createCustomRule(payload)
      .then(() => {
        dispatch(addCustomRule(payload));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "An error occurred" }));
      });
  }
};

export const editCustomRule = (payload: CreateCustomRule): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ActiveRulesService.updateCustomRule(payload)
      .then(() => {
        dispatch(updateCustomRule(payload));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "An error occurred" }));
      });
  }
};

export const deleteCustomRule = (id: string): AppThunk => async (
  dispatch,
  store
) => {
  if (localStorage.getItem("token")) {
    await ActiveRulesService.deleteCustomRule(id)
      .then(() => {
        dispatch(deleteRule(id));
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(setIsTokenValid(false));
        }

        dispatch(addSnackbar({ text: "An error occurred" }));
      });
  }
};

export const { reducer } = slice;
export const {
  setType,
  setRules,
  setActive,
  addCustomRule,
  addRule,
  updateCustomRule,
  deleteRule,
} = slice.actions;
