import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

import useStyles from './active-rules-switch.styles';

interface IProps {
  handleActivate: (activate: boolean) => void;
  isChecked: boolean;
}

const ActiveRulesSwitch = ({ handleActivate, isChecked }: IProps) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      label=""
      control={
        <Switch
          checked={isChecked}
          onChange={(_, value) => handleActivate(value)}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
        />
      }
    />
  );
};
export default ActiveRulesSwitch;
