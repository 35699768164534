import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, IconButton, Collapse } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';

import CollapsibleText from 'components/collapsible-text';
import ControlPreviewSkeleton from 'components/skeletons/control-preview-skeleton';
import RulesTableContainer from '../../../rules-table-container';

import { ComplianceControl } from 'interfaces/compliance-interface';

import useStyles from './control-preview-row.styles';

interface IProps {
  loading?: boolean;
  control: ComplianceControl;
}

const ControlPreviewRow = ({ control, loading }: IProps) => {
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const zeroViolations = control.violations_count === 0;
  const controlDescription = control.Control.description;

  if (loading) {
    return <ControlPreviewSkeleton rows={1} />;
  }

  const toggleFullName = () => {
    !zeroViolations && setShowFullName(!showFullName);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={clsx(classes.rowContainer, { [classes.disabled]: zeroViolations })}
      >
        <Grid item sm={9} onClick={toggleFullName}>
          <Grid container spacing={10}>
            <Grid item sm={12} md={1} className={classes.rowTitle}>
              {control.Control.control_id}
            </Grid>
            <Grid item sm={12} md={controlDescription ? 5 : 10}>
              <CollapsibleText text={control.Control.name || 'Not available'} maxLength={130} />
            </Grid>
            {controlDescription && (
              <Grid item sm={12} md={5}>
                <CollapsibleText text={controlDescription || 'Not available'} maxLength={130} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.actionButtonsWrapper}>
          <Grid container justify="space-around" alignItems="center" spacing={5}>
            <Grid item xs={7} className={classes.violationsContainer}>
              <div className={clsx(classes.compliantBoxColor, classes.violationsBox)}>
                {control.compliant_count}
              </div>
              <div className={clsx(classes.violationsBoxColor, classes.violationsBox)}>
                {control.violations_count}
              </div>
            </Grid>

            <Grid item xs={2}>
              {zeroViolations ? (
                <CheckCircleIcon color="secondary" />
              ) : (
                <WarningIcon color="error" />
              )}
            </Grid>
            <Grid item xs={3}>
              <IconButton
                disabled={zeroViolations}
                className={classes.expandButton}
                size="small"
                onClick={() => setExpanded(!isExpanded)}
              >
                <ExpandMoreIcon
                  className={clsx({
                    [classes.rotateIcon]: isExpanded
                  })}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <RulesTableContainer controlId={control.Control.node_id} />
      </Collapse>
    </>
  );
};

export default ControlPreviewRow;
