import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as InventoryStore from "redux/features/inventory-store";

import { AppState } from "interfaces/app-state-interface";
import {
  InventoryServiceArn,
  ResourceTag,
  InventoryResourceAttribute,
} from "interfaces/inventory-interface";
import { FetchHook } from "interfaces/hooks-interface";

type ReturnType = [ResourceTag[], InventoryResourceAttribute];
const useArnResource = (arnId: string): FetchHook<ReturnType> => {
  const [loading, setLoading] = useState(false);
  const arns = useSelector<AppState, InventoryServiceArn[]>(
    (state) => state.inventory.arns
  );
  const arn = useMemo(() => arns.find((item) => item.arn === arnId), [
    arnId,
    arns,
  ]);
  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onPageInit();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onPageInit = async () => {
    setLoading(true);

    await dispatch(InventoryStore.fetchResource(arnId));

    setLoading(false);
  };

  if (arn && arn.resource && !loading) {
    const { tags, ...attributes } = arn.resource;

    return {
      loading,
      data: [tags, attributes],
    };
  }

  return {
    loading,
    data: [[], {}],
  };
};

export default useArnResource;
