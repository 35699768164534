import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableBorder: {
    border: `solid 1px ${theme.palette.alto.main}`
  },
  columnTitle: {
    padding: theme.spacing(3, 8)
  },
  sortBtn: {
    cursor: 'pointer'
  }
}));
